import { Modal, Flex, Input } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { handleList, handleMultiList } from "../../../api/index.js";
import { useSelector } from "react-redux";
import store from "../../../store/index.js";
import { saveLoading } from "../../../store/reducer.js";
import {BI, formatUnit, parseUnit} from "@ckb-lumos/bi";
import useIDB from "../../../useHook/useIndexDB.jsx";
import useQueue from "../../../useHook/useQueue.jsx";
import ImageComponent from "../../image.jsx";
import {formatPrice, shortAddress} from "../../../utils/global.js";
import CkbImg from "../../../assets/ckb.png";

const Box = styled.div`

    .item{
        margin: 10px 0;
        gap:20px;
    }
    .num{
        font-size: 18px;
        font-weight: bold;
    }
    .symbol{
        opacity: 0.6;
        font-size: 12px;
    }
    .total{
        margin-left: 10px;
    }
`

const UlBox = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    img{
        width: 20px;
        height: 20px;
    }
`

export default function ListModal({ handleClose, show, selectItem, handleResult,isDid }) {
    const [total, setTotal] = useState(100)
    // const [price, setPrice] = useState('')
    const connectData = useSelector(store => store.connectData);
    const account = useSelector(store => store.account);

    const joyidInfo = useSelector(store => store.joyidInfo);
    const {addRecord} = useIDB();
    const {addQueueRecord,queueList} = useQueue();

    useEffect(() => {
        // let sum = selectItem.length * price

        let sum = BI.from(0);
        selectItem?.map((item) => {
            sum = sum.add(item.price);
        })

        setTotal(sum.toString())

    }, [selectItem]);


    const handleConfirm = async () => {

        store.dispatch(saveLoading(true));

        try {
            let txHash = await handleMultiList(connectData, account, selectItem,addRecord,joyidInfo==null,addQueueRecord,queueList,isDid)
            handleResult('success', `Success: `, txHash, true)
        } catch (e) {
            console.error("list", e)
            handleResult('error', e, true)
        } finally {
            handleClose();
            store.dispatch(saveLoading(false));
        }
    }

    return <div>
        <Modal
            title="List"
            centered
            zIndex={98}
            open={show}
            onOk={() => handleConfirm()}
            onCancel={() => handleClose()}
        >
            <Box>
                <UlBox>
                    {
                        selectItem.map((item, index) => (
                            <li key={index} className="flex justify-between w-full border-b pb-2 ">
                                <div className="flex gap-2 items-center">
                                    <ImageComponent tokenKey={item?.output?.type?.args} data={item?.output_data}  size="50px" isDid={isDid}/>
                                    {
                                        !isDid && <div>
                                            <div className="text-xs text-slate-400">DOB ID</div>
                                            <div>{shortAddress(item.token_key || item?.output?.type?.args, 5)}</div>
                                        </div>
                                    }

                                </div>
                                <div className="flex justify-center flex-col items-end text-xs">
                                    <PriceBox><img src={CkbImg} alt=""/>{formatUnit(item.price, "ckb")}
                                        <span>CKB</span></PriceBox>

                                    {/*<div className="text-primary011 text-sm">$ {formatPrice(item,priceUsdt)}</div>*/}
                                </div>
                            </li>
                        ))
                    }

                </UlBox>

                <Flex align="center" justify="center" className="item bg-gray-50 border rounded py-2">
                    <div>Buy <span className="text-primary011 font-din text-xl">{selectItem?.length}</span> NFT, <span className="total">Total</span></div>
                    <div>
                        <span className="text-primary011 font-din text-xl">{formatUnit(total.toString(),"ckb").toString()}</span> <span className="symbol">CKB</span>
                    </div>
                </Flex>


            </Box>

        </Modal>
    </div>
}
