import { house,copyImage,featherSquare,tabArrowUpRight } from '@lucide/lab';



export const items =[
    {
        key: "marketplace",
        label: `Buy`,
        // icon:"Store"
        icon:house

    },
    {
        key: "myDOBs",
        label: `List`,
        icon:copyImage

    },
    {
        key: "myOrders",
        label: `Orders`,
        icon:featherSquare
    },
    {
        key: "transfer",
        label: `Transfer`,
        icon:tabArrowUpRight

    }
]
