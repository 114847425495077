import axios from "axios";
import store from "../store/index.js";
import {saveAccount,} from "../store/reducer.js";




const baseUrl = import.meta.env.VITE_CHAIN === "testnet" ?"https://api.testnet.joyid.dev/api/v1":"https://api.joy.id/api/v1"

const instance = axios.create({
  baseURL:baseUrl,
  timeout: 15000,
  // headers: { "content-type": "application/json" },
});

instance.interceptors.request.use(function (config) {

  // if(config.url.includes("login") ||config.url.includes("mempool")){
  //   return config;
  // }
  //
  //
  // if (!config.headers) {
  //   config.headers = {};
  // }
  //
  // const token = state.token;
  // config.headers['Authorization'] = `Bearer ${token || ''}`;


  return config;
});

instance.interceptors.response.use(
  (response) => {

    return response;
  },
  (error) => {
    // const {response} = error;
    // if(response?.status === 401) {
    //   store.dispatch(saveAccount(null));
    // }
    return Promise.reject(error);
  },
);

/**
 * get
 * @method get
 * @param {url, params, loading}
 */
const get = function (url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * post
 * @method post
 * @param {url, params}
 */
const post = function (url, data) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * put
 * @method put
 * @param {url, params}
 */
const put = function (url, data) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const rdelete = function (url, params) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default { get, post, put, delete: rdelete };
