import React, { useEffect, useState } from 'react';
import {Button, notification, Switch, Table, Tag} from 'antd';
import ListModal from "./modal/list.jsx";
import CancelModal from "./modal/Cancel.jsx";
import styled from "styled-components";
import { shortAddress } from "../../utils/global.js";
import { BI, formatUnit } from "@ckb-lumos/bi";
import CkbImg from "../../assets/ckb.png";
import { getMySporeOrder, getSporesByRPC } from "../../api/index.js";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { OrderArgs as OrderArqs } from "@nervina-labs/ckb-dex";
import store from "../../store/index.js";
import { saveLoading } from "../../store/reducer.js";
import { PAGE_SIZE } from "../../utils/const.js";
import ImageComponent from "../image.jsx";
import ClusterName from "../clusterName.jsx";
import {unpackToRawSporeData} from "@spore-sdk/core";
import {useNavigate, useSearchParams} from "react-router-dom";
import useIDB from "../../useHook/useIndexDB.jsx";

const Box = styled.div`
    padding: 30px;
    .nft{
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
    }

`
const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img{
        width: 24px;
        height: 24px;
    }
`

const FlexLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`


const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span{
        padding: 20px;
        cursor: pointer;
        text-decoration: underline;
    }

`

export default function MyOrders() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showCancel, setShowCancel] = useState(false);
    const [list, setList] = useState([])
    const [last, setLast] = useState('');
    const account = useSelector(store => store.account);
    const [selectItem, setSelectItem] = useState([]);


    const {isExist} = useIDB();

    const navigate = useNavigate();
    const [search] = useSearchParams();
    const page = search.get("page");
    const isSpore = search.get("isSpore") ?? "true";

    const columns = [
        {
            title: 'DOB',
            render: (_, record) => <ImageComponent tokenKey={record.output?.type?.args} data={record.output_data} size="50px"  isDid={isSpore === "false"} />
        },
        {
            title: 'DOB ID',
            render: (_, record) => <span>{shortAddress(record.output?.type?.args)}</span>
        },
        {
            title: 'Tx',
            dataIndex: 'tx',
            render: (_, record) => <span>{shortAddress(record.out_point?.tx_hash)}</span>
        },
        {
            title: 'Cluster ID',
            render: (_, record) => <span>{shortAddress(record.asset?.clusterId)}</span>
        },
        {
            title: 'Cluster Name',
            render: (_, record) => <ClusterName clusterId={record.asset?.clusterId} />
        },
        // {
        //     title: 'Occupied',
        //     dataIndex: 'occupied',
        //     render: (_, record) => <Tag>&lt;{formatUnit(record.Occupied,'ckb') } CKBytes&gt;</Tag>
        // },
        {
            title: 'Price',
            dataIndex: 'price',
            key: "price",
            render: (_, record) => <PriceBox> <img src={CkbImg} alt="" />{formatPrice(record)} <span>CKB</span></PriceBox>
        },
    ];

    const formatPrice = (element) => {
        let outputArgs = element.output.lock.args;
        if (outputArgs) {
            const orderAras = OrderArqs.fromHex(outputArgs);
            const { totalValue } = orderAras
            let rt = BI.from(totalValue).sub(7000000000);

            return formatUnit(rt.toString(), 'ckb')
        }
    }



    useEffect(() => {
        if (!account) return;
        getList()
    }, [account,page,isSpore]);

    const getList = async () => {
        store.dispatch(saveLoading(true));
        try {
            setSelectItem([])
            setSelectedRowKeys([])
            let rt = await getMySporeOrder(account, PAGE_SIZE, page,isSpore === "false");
            const { objects, last_cursor } = rt;
            let arr = objects.map(item => {
                const asset = unpackToRawSporeData(item.output_data);
                return {
                    ...item,
                    asset,
                    key: uuidv4()
                }
            })
            // setList([...list, ...arr])
            // setMore(last_cursor !== "0x")
            setList([...arr])
            setLast(last_cursor)
        } catch (e) {
            console.error(e)
        } finally {
            store.dispatch(saveLoading(false));
        }

    }


    const onSelectChange = (newSelectedRowKeys) => {

        let newSeletItem = []
        list.map((item) => {
            newSelectedRowKeys.map((sl) => {
                if (item.key === sl) {
                    newSeletItem.push(item)
                }
            })
        })

        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps:(record) => ({
            disabled: isExist(record.out_point)
        })
    };
    const hasSelected = selectedRowKeys.length > 0;


    const handleCloseCancel = () => {
        setShowCancel(false);
    }


    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const handleResult = (type, tip, desc) => {
        openNotificationWithIcon(type, tip, desc)
        setTimeout(() => {
            window.location.reload()
        }, 2000)


    }

    const onChange = (checked) => {
        navigate(`?isSpore=${checked}`)
    };
    const getMore = () =>{

        navigate(`?page=${last}`)
    }

    return <div className="w-full h-full overflow-auto">

        {
            showCancel && <CancelModal handleClose={handleCloseCancel} show={showCancel} selectItem={selectItem} handleResult={handleResult}  isDid={isSpore === "false"} />
        }
        {contextHolder}
        <Box>
            <FlexLine>
                <div className="flex items-center gap-2">
                    <Switch value={isSpore === "true"} onChange={onChange} checkedChildren="Spore"
                            unCheckedChildren="Did"/>
                    <button
                        className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed"
                        disabled={!selectItem?.length} onClick={() => setShowCancel(true)}>
                        Cancel
                    </button>
                </div>


                <span
                    className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}
                </span>

            </FlexLine>
            <Table rowSelection={rowSelection} columns={columns} dataSource={list} pagination={false}/>

            <PageBox>
                {
                    !!page && <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    last !== "0x" && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>
        </Box>
    </div>
}
