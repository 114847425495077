import {Flex, Modal} from "antd";
import {BI, formatUnit} from "@ckb-lumos/bi";
import {RPC} from "@ckb-lumos/lumos";

import {
    CHAIN_LIST,
    PAGE_SIZE,
} from "../../../utils/const.js";
import {build_transfer_dob} from "../../../utils/transactionBatch.js";
import {useSelector} from "react-redux";
import {sendRawTx} from "../../../utils/ckbUtils.js";
import store from "../../../store/index.js";
import {saveLoading} from "../../../store/reducer.js";
import styled from "styled-components";
import useIDB from "../../../useHook/useIndexDB.jsx";
import ImageComponent from "../../image.jsx";
import {shortAddress} from "../../../utils/global.js";
import CkbImg from "../../../assets/ckb.png";
import React from "react";
import {getFeeRate} from "../../../api/index.js";

const { CKB_NODE_RPC_URL} = CHAIN_LIST[import.meta.env.VITE_CHAIN];

const Box = styled.div`

    .item{
        margin: 10px 0;
        gap:20px;
    }
    .num{
        font-size: 18px;
        font-weight: bold;
    }
    .symbol{
        opacity: 0.6;
        font-size: 12px;
    }
    .total{
        margin-left: 10px;
    }
`

const UlBox = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`

const PriceBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    gap: 5px;
    img{
        width: 20px;
        height: 20px;
    }
`


export default function TransferModal({ handleClose, show,toAddress, selectItem, handleResult,isDid }){
    const account = useSelector(store => store.account);

    const {addRecord} = useIDB();



    const handleConfirm = async () => {
        // store.dispatch(saveLoading(true));
        try{
            // const rpc = new RPC(CKB_NODE_RPC_URL)
            let rt =  await getFeeRate();

            const {median} = rt;

            let feeRate = BI.from(median).toString()

            let txSkeletonObj = await build_transfer_dob(feeRate,selectItem,account,toAddress,"rei",isDid)


            let result = await sendRawTx(txSkeletonObj,"rei",account)

            for (let i = 0; i < selectItem.length; i++) {
                let item = selectItem[i];
                let outpoint = {
                    tx_hash:item.out_point.tx_hash,
                    index:item.out_point.index
                }
                addRecord(outpoint)
            }
            handleResult('success', "Success", result)


        }catch (e){

            console.log(e)
            handleResult('error', "Failed", e.message)

        }finally {
            store.dispatch(saveLoading(false));
            handleClose()

        }

    }

    const formatPrice = (str) =>{
        const price = BI.from(str).toString()
        return formatUnit(price,"ckb")
    }


    return <div>
        <Modal
            title="Transfer"
            centered
            open={show}
            onOk={() => handleConfirm()}
            onCancel={() => handleClose()}
        >
            <Box>

                <UlBox>
                    {
                        selectItem.map((item, index) => (
                            <li key={index} className="flex justify-between w-full border-b pb-2 ">
                                <div className="flex gap-2 items-center">
                                    <ImageComponent key={index} tokenKey={item.output?.type?.args}
                                                    data={item.output_data} size="50px"/>
                                    <div>
                                        <div className="text-xs text-slate-400">DOB ID</div>
                                        <div>{shortAddress(item.output?.type?.args, 5)}</div>
                                    </div>
                                </div>
                                <div className="flex justify-center flex-col items-end text-xs">
                                    <PriceBox>
                                        <div className="text-slate-400">Capacity</div>
                                        <div className="flex items-center gap-2">
                                            {/*<img src={CkbImg} alt=""/>*/}
                                            <span
                                                className="text-primary011 font-din text-sm">  {formatPrice(item.output.capacity)}</span>

                                            <span>CKB</span>

                                        </div>

                                    </PriceBox>
                                    {/*<div className="text-primary011 text-sm">$ {formatPrice(item,priceUsdt)}</div>*/}
                                </div>
                            </li>
                        ))
                    }

                </UlBox>
                <div className="flex justify-center bg-gray-50 border rounded py-2 items-end gap-3">
                    <div>Transfer <span className="text-primary011 font-din text-xl">{selectItem?.length}</span> NFT,
                    </div>
                    <div className="break-all  flex gap-1">
                        <span className="text-slate-400">To</span>
                        <span >{shortAddress(toAddress, 10)}</span>
                    </div>
                </div>
            </Box>
        </Modal>
    </div>
}
