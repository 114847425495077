import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {unpackToRawSporeData} from "@spore-sdk/core";

import {shortAddress} from "../../utils/global.js";
import {Table, Button, notification,Input,Switch} from 'antd';
import styled from "styled-components";
import TransferModal from "./modal/transferModal.jsx";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import ImageComponent from "../image.jsx";


import {CHAIN_LIST, PAGE_SIZE} from "../../utils/const.js";
import ClusterName from "../clusterName.jsx";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getSporesByRPC} from "../../api/index.js";
import useIDB from "../../useHook/useIndexDB.jsx";


const Box = styled.div`
    padding: 30px;
    .contentBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        button{
            width: 100px;
        }
    }
.nft{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
`

const LftBox = styled.div`
    display: flex;
    width: 60%;
    gap: 10px;
    align-items: center;
`

const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span{
        padding: 20px;
        cursor: pointer;
        text-decoration: underline;
    }
 
`


export default function Transfer(){
    const account = useSelector(store => store.account);
    const [list,setList] = useState([])
    const [last,setLast] = useState('');
    const [more,setMore] = useState(false)
    const [selectItem,setSelectItem] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showBuy, setShowBuy] = useState(false);
    const [toAddress, setToAddress] = useState("");


    const navigate = useNavigate();
    const [search] = useSearchParams();

    const {isExist} = useIDB();

    const page = search.get("page");

    const {type} = useParams();
    const isDid = type !== "spore"


    const columns = [
        {
            title: 'DOB',

            render: (_, record) => <ImageComponent tokenKey={record.output?.type?.args} data={record.output_data} isDid={isDid} size="50px" />

        },

        {
            title: 'DOB ID',

            render: (_, record) => <span>{shortAddress(record.output?.type?.args)}</span>
        },
        {
            title: 'Cluster ID',
            dataIndex: 'tx',
            key:"tx",
            render: (_, record) => <span>{shortAddress(record.asset?.clusterId)}</span>
        },
        {
            title: 'Cluster Name',
            render: (_, record) => <ClusterName clusterId={record.asset?.clusterId} />
        },
    ];

    const handleClose = () => {
        setShowBuy(false);
    }

    useEffect(() => {
        if(!account)return;
        getList()
    }, [account,page,isDid]);

    const getList = async() =>{
        store.dispatch(saveLoading(true));
        try{

            setSelectItem([])
            setSelectedRowKeys([])
            let rt = await getSporesByRPC(account,PAGE_SIZE,page,!isDid);
            const {objects,last_cursor} = rt;

            let arr = []

            for(let i=0;i<objects.length;i++){
                let item = objects[i];
                // const itemDobId = item.cellOutput.type.args;
                try{
                    const asset = unpackToRawSporeData(item.output_data);
                    arr.push({...item,asset,price:0});
                }catch(e){
                    arr.push({...item,price:0,asset:{
                            contentType:"image",
                            data:""
                        }});
                    console.error("Get dob info failed",e)
                }
            }

            setMore(last_cursor !== "0x")
            setList([...arr])
            setLast(last_cursor)

        }catch (e) {
            console.error(e)
        }finally {
            store.dispatch(saveLoading(false));
        }

        // setSporeList(newArr)
        store.dispatch(saveLoading(false));

    }
    const onSelectChange = (newSelectedRowKeys) => {
        let newSeletItem = []
        list.map((item) => {
            newSelectedRowKeys.map((sl) => {
                if (item.output?.type?.args === sl) {
                    newSeletItem.push(item)
                }
            })
        })

        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps:(record) => ({
        disabled: isExist(record.out_point)
    })
    };
    const hasSelected = selectedRowKeys.length > 0;


    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const handleResult = (type, tip, desc) => {
        openNotificationWithIcon(type, tip, desc)
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }

    const getMore = () =>{
        navigate(`?page=${last}&isDid=${isDid}`)
    }



    return <div className="w-full h-full overflow-auto">


        {
            showBuy && <TransferModal handleClose={handleClose} show={showBuy} selectItem={selectItem} handleResult={handleResult} toAddress={toAddress} isDid={isDid}  />
        }
        {contextHolder}
        <Box>
            <div className="contentBox"
            >
                <LftBox>
                    <Input rootClassName="w-[420px]" value={toAddress} onChange={(e)=>setToAddress(e.target.value)} size="large" />
                    <button  className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed" disabled={!selectItem?.length || !toAddress} onClick={() => setShowBuy(true)}>
                        Transfer
                    </button>
                </LftBox>


                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
        <span className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}
                </span>
                </span>
            </div>
            <Table rowSelection={rowSelection} rowKey={(row) => {
                return row.output?.type?.args;
            }} columns={columns} dataSource={list} pagination={false}/>

            <PageBox>
                {
                    !!page && <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    more && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>
        </Box>

    </div>
}
