import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";
import {formatOutpoint} from "../utils/ckbUtils.js";

export default function useIDB() {
    const { add,getAll,deleteRecord } = useIndexedDB("pendingList");

    const [list,setList] = useState([]);

    useEffect(() => {
        getAllList()
    }, []);

    const getAllList = async() =>{
        let rt = await getAll();
        setList(rt)
    }


    const deleteExpired = async () =>{
        try{
            let rt = await getAll();
            const now = new Date().valueOf() ;
            const expiredArr = rt.filter(item=>item.timestamp +  60 * 60 * 1000 <= now);
            for (let i = 0; i < expiredArr.length; i++) {
                const rid = expiredArr[i]?.id;
                if(!rid) continue;
                deleteRecord(rid).catch((error) => {
                    console.error("error", error);
                });
            }

        }catch (e) {
            console.error("deleteExpire",e)
        }
    }


    const addRecord = async(outpoint) =>{
        const timeStamp = new Date().valueOf();
        let obj={
            outpoint:formatOutpoint(outpoint),
            timeStamp
        }
        try{
            await add(obj)
        }catch (error) {
            console.error("error", error);
        }
    }



    const isExist = (outpoint) =>{
        const newOP = formatOutpoint(outpoint);

        const  findArr = list.find((item)=>item.outpoint === newOP);
        // const rt = await getByIndex("outpoint",newOP);
        return !!findArr;


    }

    return {deleteExpired,addRecord,isExist}
}
