export const DBConfig = {
    name: "MyDB",
    version: 6,
    objectStoresMeta: [
        {
            store: "pendingList",
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "outpoint", keypath: "outpoint", options: { unique: true } },
                { name: "timeStamp", keypath: "timeStamp", options: { unique: false } },
            ],
        },
        {
            store: "queueList",
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "txHash", keypath: "txHash", options: { unique: true } },
                { name: "timeStamp", keypath: "timeStamp", options: { unique: false } },
            ],
        },
    ],
};
