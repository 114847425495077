import store from "../store/index.js";
import {CHAIN_LIST, DECODER_SERVER, UTXO_PEROID} from "./const.js";
import {saveUtxo} from "../store/reducer.js";
import {formatUnit} from "@ckb-lumos/bi";
import BigNumber from "bignumber.js";
import {Indexer} from "@ckb-lumos/lumos";

export const shortAddress = (addr,num=5) => {
    if(!addr) return "";
    const address  = addr?.trim().toString();
    const frontStr = address?.substring(0, num);

    const afterStr = address?.substring(address.length - num, address.length);

    return `${frontStr}...${afterStr}`;
};


export const getUtxoStore = () =>{
    const state = store.getState();
    const {utxo} = state;
    const date = new Date().valueOf();
    return  utxo.filter((utx)=>utx.timeStamp  + UTXO_PEROID > date );
}

export const setUtxo = (txSkeleton) => {
    const inputsArr = txSkeleton.get("inputs").toArray();
    const utxoList = getUtxoStore();
    for (let i = 0; i < inputsArr.length; i++) {
        utxoList.push({
            timeStamp:new Date().valueOf(),
            index:inputsArr[i].outPoint.index,
            txHash:inputsArr[i].outPoint.txHash
        })
    }
    store.dispatch(saveUtxo(utxoList))

}

export const formatPrice = (element,priceUsdt,format=false) => {

    let pr = format?element?.price:formatUnit(element?.price , "ckb") ;

    let usdt = new BigNumber(pr).times(priceUsdt);
    return usdt.toFixed(2)
}

export const statusTag = (num) =>{
    let str
    switch (num){
        case 0:
            str="Unlisted";
            break;
       case 1:
            str="Listed";
            break;
        case 2:
            str="Sold";
            break;
        case 3:
            str="Pending";
            break;

    }
    return str;
}

export const getDID = async() =>{
    const net = import.meta.env.VITE_CHAIN
    const rpcUrl = CHAIN_LIST[net].CKB_NODE_RPC_URL;
    const indexUrl = CHAIN_LIST[net].CKB_INDEXER_URL;
    const indexer = new Indexer(indexUrl, rpcUrl);

    const  args =net==="testnet"?"0x80f0d4bf6b3951911aa6b98cc609d477a8a10b903b35cfd528d098e95c36f680":"0x62312cd846659e188b05da11dc3f080b083c27371ea701d6026e11e713e0e3de"
    const didRT = indexer.collector({
        type: {
            script: {
                codeHash:
                    "0x00000000000000000000000000000000000000000000000000545950455f4944",
                hashType: "type",
                args,

            },
            searchMode: "exact",
        }
    });
    let rt = await didRT.collect();
    let didRTJSON = await rt.next()
    const {outPoint:{txHash}} = didRTJSON.value
    return txHash;
}
