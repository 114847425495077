import { Modal, Flex, notification } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { buy_rei, getFeeRate, handleBuildTakerTx, handleBuildTakerTxWithReiWallet } from "../../../api/index.js";
import { useSelector } from "react-redux";
import { OrderArgs as OrderArqs } from "@nervina-labs/ckb-dex";
import { BI, formatUnit } from "@ckb-lumos/bi";
import store from "../../../store/index.js";
import { saveLoading } from "../../../store/reducer.js";
import ImageComponent from "../../image.jsx";
import useIDB from "../../../useHook/useIndexDB.jsx";
import { helpers } from "@ckb-lumos/lumos";
import useQueue from "../../../useHook/useQueue.jsx";
import {formatPrice, shortAddress} from "../../../utils/global.js";
import CkbImg from "../../../assets/ckb.png";
import {useSearchParams} from "react-router-dom";
import {formatOutpoint} from "../../../utils/ckbUtils.js";

const Box = styled.div`

    .item{
        margin: 10px 0;
        gap:20px;
    }
    .num{
        font-size: 18px;
        font-weight: bold;
    }
    .symbol{
        opacity: 0.6;
        font-size: 12px;
    }
    .total{
        margin-left: 10px;
    }
`

const UlBox = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    img{
        width: 20px;
        height: 20px;
    }
`

export default function BuyModal({ handleClose, show, selectItem, handleResult,priceUsdt }) {
    const connectData = useSelector(store => store.connectData);
    const account = useSelector(store => store.account);
    const joyidInfo = useSelector(store => store.joyidInfo);
    const [price, setPrice] = useState(0)
    const [search] = useSearchParams();
    const type = search.get("type");

    const { addRecord } = useIDB();
    const {addQueueRecord,queueList} = useQueue();

    let Sum = BI.from(0)

    useEffect(() => {


        selectItem?.map((item) => {
            Sum = Sum.add(item.price)
        })

        // let sumFormat = formatUnit(Sum, "ckb")
        setPrice(Sum.toString())

    }, [selectItem]);

    const handleConfirm = async () => {
        store.dispatch(saveLoading(true));

        console.log(selectItem)

        let arr = [];
        selectItem.map((item)=>{
            const {tx_hash,tx_output_index:index} = item;
            let output = {
                tx_hash,
                index
            }

            arr.push({
                ...item,
                out_point:formatOutpoint(output)
            })
        })

        try {

            if (joyidInfo == null) {
                let tx = await handleBuildTakerTxWithReiWallet(account, arr, addRecord,addQueueRecord,queueList)
                handleResult('success', "Success", tx)

            } else {
                let txHash = await handleBuildTakerTx(connectData, account, arr, addRecord,addQueueRecord,queueList)
                handleResult('success', "Success", txHash)
            }

        } catch (e) {
            console.error("submitBuy", e)
            handleResult('error', "Failed", e.message)
        } finally {
            handleClose()
            store.dispatch(saveLoading(false));
        }


    }
    return <div>

        <Modal
            title="Buy"
            centered
            zIndex={98}
            open={show}
            onOk={() => handleConfirm()}
            onCancel={() => handleClose()}
        >
            <Box>


                <UlBox>
                    {
                        selectItem.map((item, index) => (
                            <li key={index} className="flex justify-between w-full border-b pb-2 ">
                                <div className="flex gap-2 items-center">
                                    <ImageComponent tokenKey={item?.type_hash}  size="50px" isDid={type === "spore_cluster_did"} />
                                    <div>
                                        <div className="text-xs text-slate-400">DOB ID</div>
                                        <div>{shortAddress(item.type_hash, 5)}</div>
                                    </div>
                                </div>
                                <div className="flex justify-end flex-col items-end text-xs">
                                    <PriceBox><img src={CkbImg} alt=""/>{item.price}
                                        <span>CKB</span></PriceBox>

                                    <div className="usdt">$ {formatPrice(item, item.ckb_usd, true)}</div>
                                </div>
                            </li>
                        ))
                    }
                </UlBox>
                <Flex align="center" justify="center" className="item bg-gray-50 border rounded py-2">
                    <div>Buy <span className="text-primary011 font-din text-xl">{selectItem?.length}</span> NFT, <span className="total">Total</span></div>
                    <div>
                        <span className="text-primary011 font-din text-xl">{price}</span> <span className="symbol">CKB</span>
                    </div>
                </Flex>

            </Box>

        </Modal>
    </div>
}
