import styled from "styled-components";

const LoadingBox = styled.div`
    /* HTML: <div class="loader"></div> */
    .loaderBtn {
        width: 30px;
        aspect-ratio: 1;
        border-radius: 50%;
        background:
                radial-gradient(farthest-side,#ffa516 94%,#0000) top/4px 4px no-repeat,
                conic-gradient(#0000 30%,#ffa516);
        -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
        animation: l13 1s infinite linear;
    }
    @keyframes l13{
        100%{transform: rotate(1turn)}
    }
`
export default function LoadingBtn(){
    return <LoadingBox>
        <div className="loaderBtn"></div>
    </LoadingBox>
}
