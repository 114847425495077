import ComingImg from "../assets/comingsoon.png";
import styled from "styled-components";

const Box = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img{
        width: 500px;
    }
    
`

export default function Coming() {
    return <Box>
        <img src={ComingImg} alt=""/>
    </Box>
}
