import { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import styled from "styled-components";
import {formatPrice, shortAddress} from "../../utils/global.js";
import BuyModal from "./modal/Buy.jsx";
// import ClusterSelect from "./clusterType.jsx";
import CkbImg from "../../assets/ckb.png";
import {PAGE_SIZE} from "../../utils/const.js";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getList_Omiga_doblist, getList_Omiga_udtlist} from "../../api/omiga.api.js";
import ClusterTypeSelect from "./clusterType.jsx";
import ImageComponent from "../image.jsx";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";

const Box = styled.div`
    padding: 30px;
.nft{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
    .usdt{
        margin-left: 34px;
        color: #ff9900;
    }

`

const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img{
        width: 24px;
        height: 24px;
    }
`

export default function OmigaHome() {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showBuy, setShowBuy] = useState(false);
    const [list, setList] = useState([])
    const [selectItem, setSelectItem] = useState([]);
    const [more, setMore] = useState(false)

    const [last,setLast] = useState('');

    const navigate = useNavigate();


    const [search] = useSearchParams();
    const category = search.get("category");
    const type = search.get("type");
    const page = search.get("page") ?? 1;




    const columns = [
        {
            title: 'DOB',
            dataIndex: 'nft',
            render: (_, record) => <><ImageComponent tokenKey={record?.type_hash}  type={type} size="50px" isDid={type === "spore_cluster_did"} /></>
        },
        {
            title: 'DOB ID',
            dataIndex: 'type_hash',
            render: (_, record) => <span>{shortAddress(record?.type_hash)}</span>
        },
        {
            title: type === "x_udt"?"Symbol":'Cluster Name',
            render: (_, record) => <span>{record?.spore?.cluster?.name || record?.symbol}</span>
        },
        {
            title: 'Owner',
            render: (_, record) => <span>{shortAddress(record.address,8)}</span>
        },

        {
            title: 'Price',
            dataIndex: 'price',
            render: (_, record) => <div>
                <div><PriceBox><img src={CkbImg} alt=""/>{record.price} <span>CKB</span></PriceBox>
                </div>
                <div className="usdt">$ {formatPrice(record, record.ckb_usd,true)}</div>
            </div>
        },
    ];


    useEffect(() => {
        if(!category || !type) return;
        getList()

    }, [page, category,type]);


    const getList = async () => {
        store.dispatch(saveLoading(true));
        setList([]);
        try{
            if (category === "" || type=== "") return;
            let result
            if(type === "x_udt"){
                result  = await getList_Omiga_udtlist(category,page, PAGE_SIZE);
            }else{
                result  = await getList_Omiga_doblist(category,page, PAGE_SIZE);
            }



            let newList = []
            newList = [...result.details];

            const newpage = Number(result.page_index)
            setList(newList);
            setLast(newpage + 1)
            setMore(result.page_count > (newpage) * PAGE_SIZE);
        }catch(err){
            console.log(err);
        }finally {
            store.dispatch(saveLoading(false));
        }


    }


    const getMore = () => {
        // getList(page + 1)
        navigate(`?page=${last}&type=${type}&category=${category??""}`)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        let newSeletItem = []

        list.map((item) => {

            newSelectedRowKeys.map((sl) => {
                if (item.type_hash === sl) {
                    newSeletItem.push(item)
                }
            })
        })
        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };



    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleClose = () => {
        setShowBuy(false);
    }
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const handleResult = (type, tip, desc) => {
        openNotificationWithIcon(type, tip, desc)
        getList()
    }

    return <div className="w-full h-full overflow-auto">

        {
            showBuy && <BuyModal handleClose={handleClose} show={showBuy} selectItem={selectItem} handleResult={handleResult} />
        }
        {contextHolder}
        <Box>
            <div className="flex justify-between items-center mb-2">
                <div className="flex">
                    <ClusterTypeSelect  />
                    <button className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed"  disabled={!selectItem?.length} onClick={() => setShowBuy(true)}>
                        Buy
                    </button>
                </div>
                <span className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}

                </span>
            </div>
            <Table rowSelection={rowSelection} rowKey="type_hash" columns={columns} dataSource={list} pagination={false}/>

            <PageBox>
                {
                    page!=1 && <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    more && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>


        </Box>
    </div>
}
