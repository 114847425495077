import { config } from "@ckb-lumos/lumos";
import JoyIdImg from "../assets/Joyid.svg";
import DobbyImg from "../assets/dobby.png";
import OmigaImg from "../assets/omiga.png";
import SporeImg from "../assets/img/spore.svg";
import DidImg from "../assets/img/bit-logo.png";

// Testnet
export const CHAIN_LIST = {
    testnet:{
        CKB_NODE_RPC_URL: "https://testnet.ckb.dev/rpc",
        CKB_INDEXER_URL:"https://testnet.ckb.dev/indexer",
        DOB_AGGREGATOR_URL:"https://cota.nervina.dev/aggregator",
        JOYID_APP_URL:"https://testnet.joyid.dev",
        CONFIG:config.TESTNET,

    },
    mainnet:{
        CKB_NODE_RPC_URL: "https://mainnet.ckbapp.dev/rpc",
        CKB_INDEXER_URL:"https://mainnet.ckbapp.dev/indexer",
        DOB_AGGREGATOR_URL:"https://cota.nervina.dev/mainnet-aggregator",
        JOYID_APP_URL:"https://app.joy.id",
        CONFIG:config.MAINNET,
    }
}


export const DID_CONTRACT = {
    mainnet:{
        CODE_HASH:"0xcfba73b58b6f30e70caed8a999748781b164ef9a1e218424a6fb55ebf641cb33",
        TX_HASH:"0x18dda0f02036305b423b85cce276a40417faed044b2ee9220284215f38734daa",
        HASH_TYPE:"type",
        INDEX: "0x0",
        DEP_TYPE:"code",
    },
    testnet:{
        CODE_HASH:"0x0b1f412fbae26853ff7d082d422c2bdd9e2ff94ee8aaec11240a5b34cc6e890f",
        TX_HASH:"0x1bda44bf86d240b739e035ade4eae2d8a53085210c6dab96b49ffeacb8c1174e",
        HASH_TYPE:"type",
        INDEX: "0x0",
        DEP_TYPE:"code",
    }
}


export const DECODER_SERVER = {
    mainnet:"https://dob-decoder.rgbpp.io/",
    testnet:"https://dob-decoder.internal.joyid.dev/"
}


export const PAGE_SIZE = 200;

export const UTXO_PEROID = 10 * 60 * 1000;

export const JOYID_CONTRACT ={
    mainnet:{
        TX_HASH:"0xc17040a3723df8f27c344d5e86e254f1d27e1181a5484cb3722416ef09d246ec",
        DEP_TYPE:"code",
        INDEX:"0x0"
    },
    testnet:{
        TX_HASH:"0xc17040a3723df8f27c344d5e86e254f1d27e1181a5484cb3722416ef09d246ec",
        DEP_TYPE:"code",
        INDEX:"0x0"
    },
}

export const MARKET_LIST =[
    {
        name:"Dobby Market",
        path:"dobby",
        icon:DobbyImg
    },
    {
        name:"Joy Market",
        path:"joyid",
        icon:JoyIdImg
    },
    {
        name:"Omiga",
        path:"omiga",
        icon:OmigaImg
    }
]

export const Spore =[
    {
        name:"Spore",
        path:"spore",
        icon:SporeImg
    },
    {
        name:".bit",
        path:"did",
        icon:DidImg
    }
]

export const Omiga_Type = [
    {
        name:"DOBs",
        type:"spore_cluster"
    },
    {
        name:"DID",
        typeHash:import.meta.env.VITE_CHAIN === "testnet" ? "0xe96a1a02db51b1cfb6615e40c69033375e27a781d147e42f6acf58fa7f80389a" :"0x6d9c5c05467fbba58b5aa0f8a748a56bb9be1a99a268b3d743a55144cce1302f",
        type:"spore_cluster_did"
    },
    {
        name:"mNFT",
        type:"m_nft"
    },
    {
        name:"xUDT",
        type:"x_udt"
    }
]

export const Omiga_URL = {
    mainnet:"https://api.omiga.io/",
    testnet:"https://test-api.omiga.io/"
}
