import MarketType from "../components/layout/type.jsx";
import ConnectBtn from "../components/connectBtn.jsx";
import Balance from "../components/balace.jsx";
import {useSelector} from "react-redux";
import MyNFT from "../components/joyid/myNFT.jsx";
import {useParams} from "react-router-dom";
import MyAsset from "../components/rei/myAsset.jsx";
import Coming from "../components/coming.jsx";

export default function Dobs() {
    const account = useSelector(store => store.account);

    const {platform} = useParams();

    return <main className="flex flex-1 flex-col h-full bg-gray-100">
        <div className="flex justify-between border-b  border-gray-300  w-full  py-6 px-8 ">
            <div>
                <div
                    className="sm:mt-20 flex text-black text-hd1mb font-Montserrat font-bold">
                    List My DOBs on Marketplaces
                </div>
                {
                    !!window?.ckb &&!!account && <Balance />
                }
            </div>

            <ConnectBtn/>
        </div>

        <div className="flex flex-1 min-h-0">
            <MarketType routerType="myDOBs"/>
            {
                platform === "joyid" &&  <MyNFT key="joyid" />
            }
            {
                platform === "dobby" &&  <MyAsset/>
            }
            {
                platform === "omiga" &&  <MyNFT  key="omiga" />
            }
        </div>
    </main>
}
