import styled from "styled-components";
import {useEffect, useState} from "react";
import {decodeDOB} from "@taoist-labs/dob-decoder";
import  SporeImg from "../assets/img/spore.svg";

import DidImg from "../assets/did.svg";
import LoadingBtn from "./loadingBtn.jsx";
import UdtImg from "../assets/img/udt.png";

const ImageBox = styled.div`
    //width:{} 100px;
    width:${props => (props.size ? props.size : "100px")};
    height: ${props => (props.size ? props.size : "100px")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f5f5f5;
    overflow: hidden;

    img{
        max-width: 100%;
        max-height: 100%;
        border-radius: 8px;
        object-position: center;
        object-fit: cover;

    }

    .line{
        background: #f8f8f8;
        width: 100%;
        height: 10px;
        margin-top: 20px;
    }
`

const ImageBox2 = styled(ImageBox)`
    border-radius: 50%;
    padding: 0;
    img{
        width:${props => (props.size ? props.size : "100px")};
        height: ${props => (props.size ? props.size : "100px")};
    }
`

const TextBox = styled.div`
        display: flex !important;
        overflow: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f5f5f5;
    .inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f8f8;
        font-size: 14px;
        font-weight: 500;


        line-height: 1.2em;
        box-sizing: border-box;
        padding: 10px;

        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 10;
        overflow: hidden;
    }
      
    
`

const ItemImgBox = styled.div`
    position: relative;
    width:${props => (props.size ? props.size : "150px")};
    height: ${props => (props.size ? props.size : "150px")};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f5f5f5;
    overflow: hidden;

    img{
        width: 100%;
    }
    .nameBox{
        position: absolute;
        color: #ffe9b7;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        top:50%;
      
        .title{
            font-size: 10px;
            font-family: din_bold;
        }
        .bit{
            font-size: 10px;
        }
    }
`

export default function ImageComponent({tokenKey,itemData,size,isDid,type}){
    const [dob,setDob] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if(!tokenKey)return;
        getInfo()
    }, [tokenKey,itemData,isDid]);

    const getInfo = async() =>{
        setLoading(true)
        try{
            if(isDid){

                let rt = await decodeDOB(tokenKey,import.meta.env.VITE_CHAIN === "testnet",undefined,"did");
                const  traitsBox = rt.data.traits;
                let arrPrev = ""
                traitsBox?.map((item)=>{

                    if(item?.name === "Account"){
                        arrPrev = item?.value ||item?.traits[0]?.String  ;
                    }
                })
                setDob({
                    ...itemData,
                    spore:{
                        DidName: arrPrev
                    }
                })

            }else if(type !== "x_udt"){
                const asset = await decodeDOB(tokenKey,import.meta.env.VITE_CHAIN === "testnet",itemData?`0x${itemData}`:undefined,"spore");
                setDob(asset);
            }
        }catch(error){
            console.log(error)
        }finally {
            setLoading(false)
        }

    }

    return <>
        {
            !!dob && !isDid &&!loading && <ImageBox size={size}>
                {
                     dob?.contentType?.indexOf("image") > -1 && <div className="photo">
                        <div className="aspect"/>
                        <div className="content">
                            <div className="innerImg">
                                <img src={dob?.data || SporeImg} alt=""/>
                            </div>
                        </div>
                    </div>
                }
                {
                    dob?.contentType?.indexOf("text") > -1 && <TextBox>
                        <div className="inner">
                            {dob?.data}
                        </div>
                    </TextBox>
                }
                {
                   dob?.contentType?.indexOf("json") > -1 && <div className="photo">
                        <div className="aspect"/>
                        <div className="content">
                            <div className="innerImg">
                                <img src={dob?.data?.url || SporeImg} alt=""/>
                            </div>
                        </div>
                    </div>
                }

                {
                   dob?.contentType?.indexOf("dob/0") > -1 && <div className="photo">
                        <div className="aspect"/>
                        <div className="content">
                            <div className="innerImg">
                                <img src={dob?.data?.imgUrl || SporeImg} alt=""/>
                            </div>
                        </div>
                    </div>
                }

            </ImageBox>
        }
        {!!dob && isDid &&!loading && <div className="flex items-center gap-2"><ItemImgBox size={size}>
            <div className="nameBox">
                {/*<div className="title">*/}
                {/*    {dob?.spore?.DidName?.split(".")[0]}*/}
                {/*</div>*/}
                {/*<div className="bit">*/}
                {/*    .bit*/}
                {/*</div>*/}

            </div>
            <img src={DidImg} alt=""/>
        </ItemImgBox>
            <div><span className="text-primary011 font-din">{dob?.spore?.DidName?.split(".")[0]}</span>.bit</div>
            </div>
        }

       {
            !dob &&!loading &&  <>

               {
                   type !== "x_udt" &&<ImageBox size={size}> <div className="photo">
                       <div className="aspect"/>
                       <div className="content">
                           <div className="innerImg">
                               <img src={SporeImg} alt=""/>
                           </div>
                       </div>
                   </div>
            </ImageBox>
               }


               {
                   type === "x_udt" && <ImageBox2 size="45px"><div className="photo">
                       <div className="aspect"/>
                       <div className="content">
                           <div className="innerImg">
                           <img src={UdtImg} alt=""/>
                           </div>
                       </div>
                   </div>
                </ImageBox2>
               }
           </>

        }
        {
            !dob &&loading &&  <ImageBox size={size}><div className="photo">
                <div className="aspect"/>
                <div className="content">
                    <div className="innerImg">
                        <LoadingBtn />
                    </div>
                </div>
            </div>     </ImageBox>
        }

    </>
}
