import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";
import {deserializeOutPoints} from "../utils/ckbUtils.js";


export default function useQueue() {
    const { add,getAll,deleteRecord } = useIndexedDB("queueList");

    const [queueList,setQueueList] = useState([]);

    useEffect(() => {
        getAllList()
    }, []);


    const getAllList = async() =>{
        let rt = await getAll();

        if(!rt)return;

         let hashArr = rt.map((item)=> item.txHash)
        setQueueList(deserializeOutPoints(hashArr))

    }

    const deleteQueueExpired = async () =>{
        try{
            let rt = await getAll();
            const now = new Date().valueOf() ;
            const expiredArr = rt.filter(item=>item.timestamp +  10 * 60 * 1000 <= now);
            for (let i = 0; i < expiredArr.length; i++) {
                const rid = expiredArr[i]?.id;
                if(!rid) continue;
                deleteRecord(rid).catch((error) => {
                    console.error("error", error);
                });
            }

        }catch (e) {
            console.error("deleteExpire",e)
        }
    }


    const addQueueRecord = async(txHashArr) =>{

        for (let i = 0; i < txHashArr.length; i++) {
            let txHash = txHashArr[i]
            const timeStamp = new Date().valueOf();
            let obj={
                txHash,
                timeStamp
            }
            try{
                await add(obj)
            }catch (error) {
                console.error("error", error);
            }
        }

    }

    return {deleteQueueExpired,addQueueRecord,queueList}
}
