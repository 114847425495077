import styled from "styled-components";
import { Modal, Flex, notification } from "antd";
import JoyidImg from "../assets/Joyid.svg";
import ReiImg from "../assets/rei.png";
import {connect} from "@joyid/ckb";
import store from "../store/index.js";
import {saveAccount, saveConnectData, saveJoyidInfo} from "../store/reducer.js";
import {CHAIN_LIST} from "../utils/const.js";

const Box = styled.div`
    dl{
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        
    }
    img{
        width: 40px;
        border: 1px solid #ccc;
        border-radius: 50px;
        padding: 3px;
    }
    dd{
        font-weight: bold;
        font-size: 16px;
    }
    
`

export default function ConnectModal({show,handleClose}) {

    const {  JOYID_APP_URL} = CHAIN_LIST[import.meta.env.VITE_CHAIN];
    const isMainnet = import.meta.env.VITE_CHAIN === "mainnet";


    const onConnect = async () => {
        try {
            const authData = await connect({
                name: "JoyID",
                logo: "https://fav.farm/🆔",
                joyidAppURL: JOYID_APP_URL,
                network: isMainnet ? "mainnet" : "testnet",
            });
            store.dispatch(saveAccount(authData.address));
            store.dispatch(saveJoyidInfo(authData));
            store.dispatch(saveConnectData(authData));
        } catch (error) {
            console.error(error);
        }finally {
            handleClose()
        }
    }


    const ConnectRei = async () => {
        try {
            let addr = await window?.ckb.request({ method: "ckb_requestAccounts" })
            store.dispatch(saveAccount(addr));
            store.dispatch(saveJoyidInfo(null));
            store.dispatch(saveConnectData(null));
        } catch (error) {
            console.error(error);
        }finally {
            handleClose()
        }
    }

    return   <Modal
        title="Connect Wallet"
        centered
        open={show}
        zIndex={98}
        footer={null}
        onCancel={() => handleClose()}
    >
        <Box>
            <dl onClick={()=>onConnect()}>
                <dt>
                    <img src={JoyidImg} alt=""/>
                </dt>
                <dd>
                    JoyId
                </dd>
            </dl>
            <dl onClick={()=>ConnectRei()}>
                <dt>
                    <img src={ReiImg} alt=""/>
                </dt>
                <dd>Rei Wallet</dd>
            </dl>
        </Box>
    </Modal>
}
