import {useEffect, useState} from 'react';
import {notification, Select} from 'antd';
import {Omiga_Type, PAGE_SIZE} from "../../utils/const.js";
import {getList_Omiga, getList_Omiga_xudt} from "../../api/omiga.api.js";
import {useNavigate, useSearchParams} from "react-router-dom";

const ClusterTypeSelect = () => {

    const [list, setList] = useState([])

    const [selectCluster,setSelectCluster] = useState(null);
    const [clusterType,setClusterType] = useState(null);

    const [search] = useSearchParams();
    const category = search.get("category")??"undefined";
    const type = search.get("type")??"undefined";
    const navigate = useNavigate();

    useEffect(() => {

        findClusterName()
    }, [type]);

    const findClusterName = () => {
        let cluster;

        if(type !=="undefined"){
            cluster = Omiga_Type.find(item => item.type === type);
        }
        else{
            cluster = Omiga_Type[0]
        }
        setClusterType(cluster?.type)

    }
    useEffect(() => {
        if (clusterType === "undefined" ||!clusterType) return;
        getList()
    }, [clusterType]);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const getList = async () => {
        try{
            let result ;
            if(clusterType==="x_udt"){
                result = await getList_Omiga_xudt(1, PAGE_SIZE);
            }else{
                let hash = ""
                if(type ==="spore_cluster_did") {
                    const clusterObj = Omiga_Type.find(item => item.type === type);
                    hash = clusterObj.typeHash ;
                }
                result = await getList_Omiga(clusterType,1, PAGE_SIZE,hash);

            }
            let newList = []
            newList = [...result.details];
            setList(newList);
            if(category !== "undefined"){
                setSelectCluster(category)
            }else{
                handleSelect(newList[0]?.type_hash ? newList[0]?.type_hash:"undefined")
                //
                // navigate(`?page=1&type=${clusterType?clusterType: "undefined"}&category=${newList[0]?.type_hash}`);
            }

        }catch(error){
            console.log(error)
            openNotificationWithIcon("error", "Get Cluster Failed")
        }

    }


    const handleType = (v) =>{
        setClusterType(v)
        if(v==="spore_cluster_did"){
            const clusterObj = Omiga_Type.find(item => item.type === v);
            navigate(`?page=1&type=${v}&category=${clusterObj.typeHash}`);
        }else{
            navigate(`?page=1&type=${v}`);
        }

    }
    const handleSelect = (v) =>{
        setSelectCluster(v)

        navigate(`?page=1&type=${clusterType ?? "undefined"}&category=${v}`);
    }


    return ( <div className="flex gap-1">
            {contextHolder}
            <Select
                className="w-[250px] mr-4"
                value={clusterType}
                size="large"
                onChange={(v) => handleType(v)}
                options={Omiga_Type}
                fieldNames={{
                    label: "name",
                    value: "type"
                }}
            />

            <Select
                className="w-[250px] mr-4"
                value={selectCluster}
                size="large"
                onChange={(v) => handleSelect(v)}
                options={list}
                fieldNames={{
                    label: "name",
                    value: "type_hash"
                }}
            />

        </div>

    );
};
export default ClusterTypeSelect;
