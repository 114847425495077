import { Modal, Flex, Input } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BI, formatUnit } from "@ckb-lumos/bi";
import { OrderArgs as OrderArqs } from "@nervina-labs/ckb-dex";
import { handleCancelOrder } from "../../../api/index.js";
import { useSelector } from "react-redux";
import store from "../../../store/index.js";
import { saveLoading } from "../../../store/reducer.js";
import useIDB from "../../../useHook/useIndexDB.jsx";
import useQueue from "../../../useHook/useQueue.jsx";
import ImageComponent from "../../image.jsx";
import {formatPrice, shortAddress} from "../../../utils/global.js";
import CkbImg from "../../../assets/ckb.png";

const Box = styled.div`

    .item{
        margin: 10px 0;
        gap:20px;
    }
    .num{
        font-size: 18px;
        font-weight: bold;
    }
    .symbol{
        opacity: 0.6;
        font-size: 12px;
    }
    .total{
        margin-left: 10px;
    }
`

const UlBox = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    img{
        width: 20px;
        height: 20px;
    }
`

export default function CancelModal({ handleClose, show, selectItem, handleResult ,isDid}) {
    const connectData = useSelector(store => store.connectData);
    const account = useSelector(store => store.account);
    const [price, setPrice] = useState(0)
    const {addRecord} = useIDB();
    const {addQueueRecord,queueList} = useQueue();

    const joyidInfo = useSelector(store => store.joyidInfo);

    let Sum = BI.from(0)

    useEffect(() => {
        selectItem.map((item) => {
            let outputArgs = item.output.lock.args;
            if (outputArgs) {
                const orderAras = OrderArqs.fromHex(outputArgs);
                const { totalValue } = orderAras
                let rt = BI.from(totalValue).sub(7000000000)
                Sum = Sum.add(rt)
            }
        })
        let sumFormat = formatUnit(Sum, "ckb")
        setPrice(sumFormat)

    }, [selectItem]);

    const handleConfirm = async () => {
        store.dispatch(saveLoading(true));
        try {
            let txHash = await handleCancelOrder(connectData, account, selectItem,addRecord,joyidInfo==null,addQueueRecord,queueList,isDid)
            handleResult('success', "Success", txHash)

        } catch (e) {
            console.error("cancel", e)
            handleResult('error', "Failed", e.message)
        } finally {
            handleClose()
            store.dispatch(saveLoading(false));
        }

    }

    const formatPrice = (element) => {
        let outputArgs = element.output.lock.args;
        if (outputArgs) {
            const orderAras = OrderArqs.fromHex(outputArgs);
            const { totalValue } = orderAras
            let rt = BI.from(totalValue).sub(7000000000);

            return formatUnit(rt.toString(), 'ckb')
        }
    }

    return <div>
        <Modal
            title="Cancel"
            centered
            zIndex={98}
            open={show}
            onOk={() => handleConfirm()}
            onCancel={() => handleClose()}
        >
            <Box>

                <UlBox>
                    {
                        selectItem.map((item, index) => (
                            <li key={index} className="flex justify-between w-full border-b pb-2 ">
                                <div className="flex gap-2 items-center">
                                    <ImageComponent key={index} tokenKey={item.output?.type?.args} data={item.output_data} size="50px" isDid={isDid}/>
                                    <div>
                                        <div className="text-xs text-slate-400">DOB ID</div>
                                        <div>{shortAddress(item.output?.type?.args, 5)}</div>
                                    </div>
                                </div>
                                <div className="flex justify-end flex-col items-end text-xs">
                                    <PriceBox><img src={CkbImg} alt=""/>{formatPrice(item)}
                                        <span>CKB</span></PriceBox>

                                    {/*<div className="text-primary011 text-sm">$ {formatPrice(item,priceUsdt)}</div>*/}
                                </div>
                            </li>
                        ))
                    }

                </UlBox>
                <Flex align="center" justify="center"  className="item bg-gray-50 border rounded py-2">
                    <div>Cancel <span className="text-primary011 font-din text-xl">{selectItem?.length}</span> NFT, <span className="total">Total</span></div>
                    <div>
                        <span className="text-primary011 font-din text-xl">{price}</span> <span
                        className="symbol">CKB</span>
                    </div>
                </Flex>

            </Box>

        </Modal>
    </div>
}
