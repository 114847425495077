import { createGlobalStyle } from "styled-components";
import "../assets/css/layout.css"
import "../assets/css/loading.css"
import "../assets/css/globals.css"


const GlobalStyle = createGlobalStyle`
    
  dl,dt,ul,li,p{
    padding: 0;
    margin: 0;
    
  }
  li {
    list-style: none;
  }
  
  a{
    text-decoration: none;
    color: #000;
  
  }
  .ant-table-selection-column{
      padding: 0 40px!important;
  }
  th.ant-table-cell{
      padding:20px!important;
  }
  .status_2{
      color: #FF5A5F;
      border: 0;
  }
  .updated{
      color:#8AC926;
  }
  .status_1{
      color: #3DA5D9;
  }
  .status_0{
      color: #FF914C;
  }
  .status_3{
      color: #F600FF;
  }
`;

export default GlobalStyle;
