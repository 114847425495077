import request from './request';
import {PAGE_SIZE} from "../utils/const.js";

export const getJoyidCategeoryList = async() =>{
    return request.get(`/markets/trusted_nfts`);
}

export const getMarketList = async(page,currentCat) =>{
    return request.get(`/markets/nft_orders?count=${PAGE_SIZE}&cursor=${page}&cluster_id=${currentCat}`);
}
