import {
    calculateFeeCompatible, generateSporeCoBuild,
 getJoyid_lock_type,
    getTransactionSizeByTx,
    updateWitness
} from "./ckbUtils.js";
import { helpers, Indexer} from "@ckb-lumos/lumos";
import {predefined} from "@ckb-lumos/config-manager";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import {BI} from "@ckb-lumos/bi";

import {CHAIN_LIST, DID_CONTRACT} from "./const.js";
import {getUtxoStore} from "./global.js";
import store from "../store/index.js";

const MAX_FEE = BI.from("20000000")

export const build_transfer_dob = async (fee,typeObj,address,toAddress,type,isDid) =>{

    const net = import.meta.env.VITE_CHAIN;
    const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;

    const sporeConfig = net==="testnet"?  predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

    const rpcUrl = CHAIN_LIST[net].CKB_NODE_RPC_URL
    const indexUrl = CHAIN_LIST[net].CKB_INDEXER_URL
    const indexer = new Indexer(indexUrl, rpcUrl);
    let myScript = helpers.addressToScript(address,{config:newConfig})
    const toScript =helpers.addressToScript(toAddress,{config:newConfig})

    let txSkeleton = helpers.TransactionSkeleton({ cellProvider: indexer });

    let needCapacity = BI.from(
        helpers.minimalCellCapacity({
            cellOutput: {
                lock: toScript,
                capacity: BI.from(0).toHexString(),
            },
            data: "0x",
        })
    ).add(MAX_FEE);


    const collect_ckb = indexer.collector({
        lock: {
            script: myScript,
            searchMode: "exact",
        },
        type: "empty",
    });

    const utxoFilter = getUtxoStore();
    const inputs_ckb = [];
    let ckb_sum = BI.from(0);
    for await (const collect of collect_ckb.collect()) {
        const useUtxo = utxoFilter.find((utxo)=> utxo.txHash === collect.outPoint.txHash && utxo.index === collect.outPoint.index)

        if (useUtxo) {
            continue;
        }

        inputs_ckb.push(collect);
        ckb_sum = ckb_sum.add(collect.cellOutput.capacity);
        if (ckb_sum.gte(needCapacity)) {
            break;
        }
    }

    if (ckb_sum.lt(needCapacity)) {
        throw new Error("Not Enough capacity found");
    }

    const {codeHash:myCodeHash,hashType:myHashType} = myScript

    let cellDep_script_lock ;

    if(type === "joyid_ckb"){
        cellDep_script_lock = getJoyid_lock_type(net)
    }else {
        for (let key in newConfig.SCRIPTS) {
            let item = newConfig.SCRIPTS[key]
            if (item.CODE_HASH === myCodeHash && item.HASH_TYPE === myHashType) {
                cellDep_script_lock = item;
                break;
            }
            throw new Error("script not found")
        }
    }


    const version = net === 'testnet'?"preview":"latest";
    const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(...inputs_ckb)
    );
    let diffSUM = BI.from(0)
    let outputArr = []

    let newTypeObj = []


    typeObj.map((tObj)=>{

        const spore = {
            cellOutput: {
                capacity: tObj.output.capacity,
                lock: {
                    codeHash: tObj.output.lock.code_hash,
                    hashType: tObj.output.lock.hash_type,
                    args: tObj.output.lock.args
                },
                type:  {
                    codeHash: tObj.output.type.code_hash,
                    hashType: tObj.output.type.hash_type,
                    args: tObj.output.type.args
                }
            },
            data: tObj.output_data,
            outPoint:{
                txHash: tObj.out_point.tx_hash,
                index: tObj.out_point.index
            } ,
            blockNumber: typeObj.block_number,
            txIndex: typeObj.tx_index,
        }
        newTypeObj.push(spore)
        txSkeleton = txSkeleton.update("inputs", (inputs) =>
            inputs.push(spore)
        );

        let inputCapacity = spore.cellOutput.capacity;
        let inputOccupid = helpers.minimalCellCapacityCompatible(spore);
        let inputMargin = BI.from(inputCapacity).sub(inputOccupid)

        const outputObj = JSON.parse(JSON.stringify(spore));
        outputObj.cellOutput.lock = toScript;

        let outputMinimal = BI.from(
            helpers.minimalCellCapacity(outputObj)
        );

        let outputCapacity = outputMinimal.add(inputMargin)
        let diff = BI.from(inputCapacity).sub(outputCapacity)
        diffSUM = diffSUM.add(diff)

        outputObj.cellOutput.capacity = `0x${outputCapacity.toString(16)}`;

        outputArr.push(outputObj);
        txSkeleton = txSkeleton.update("outputs", (outputs) =>
            outputs.push(outputObj)
        );

    })


    const outputCapacityChange  = ckb_sum.sub(MAX_FEE);

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${outputCapacityChange.toString(16)}` ,
                lock: myScript,
            },
            data:"0x"
        })
    );


    const {TX_HASH:tx_hash_lock,INDEX:index_lock,DEP_TYPE:dep_type_lock} = cellDep_script_lock

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": tx_hash_lock,
                "index": index_lock
            },
            "depType": dep_type_lock
        })
    );
    if(!isDid){
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push( sporeTypeScript.cellDep)
        );
    }else{
        const state = store.getState();
        const {didHash} = state;
        const {INDEX,DEP_TYPE} = DID_CONTRACT[net];
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push(    {
                "outPoint": {
                    "txHash": didHash,
                    "index": INDEX
                },
                "depType": DEP_TYPE
            })
        );

    }




    let  sporeCoBuild = generateSporeCoBuild(newTypeObj, outputArr)
    txSkeleton = await updateWitness(txSkeleton,myScript,newTypeObj[0].cellOutput.type.codeHash,sporeCoBuild,type)

    const unsignedTx = helpers.createTransactionFromSkeleton(txSkeleton);


    const size =  getTransactionSizeByTx(unsignedTx)
    const newFee = calculateFeeCompatible(size,fee);


    const outputCapacityFact  = ckb_sum.sub(newFee).add(diffSUM);

    let outputs = txSkeleton.get("outputs").toArray();
    let item = outputs[outputs.length - 1];
    item.cellOutput.capacity = outputCapacityFact.toHexString();
    txSkeleton = txSkeleton.update("outputs", (outputs) => {
        outputs.set(outputs.size - 1,item)
        return outputs;
    });

    return txSkeleton
}
