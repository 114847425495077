import {useEffect, useState} from "react";
import LogoImg from "../../assets/img/logo.png";
import TgLogo from "../../assets/img/Telegram.svg";
import { AlignJustify, ChevronsLeft, ChevronsRight, LayoutDashboard, NotebookText, SendToBack } from 'lucide-react';
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {items} from "../../constant/menu.jsx";
import {CHAIN_LIST} from "../../utils/const.js";
import {Indexer} from "@ckb-lumos/lumos";
import store from "../../store/index.js";
import {saveClusterList, saveHash} from "../../store/reducer.js";
import Loading from "../loading.jsx";
import {useSelector} from "react-redux";
import { Icon } from 'lucide-react';
import {getClusterList} from "../../api/dexRpc.js";

const LinkBox = styled.div`
    position: fixed;
    left:20px;
  bottom: 40px;

  a{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  img{
    width: 24px;
  }
  span{
    opacity: 0.6;
    font-size: 12px;
    display: ${props => props.isopen === "true"? "block":"none"};
  }
  
`

export default function Menu(){
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const [isOpen, setIsOpen] = useState(false);
  const [isColleapse, setIsColleapse] = useState(true);
  const navigate = useNavigate()
  const activeTab = location.pathname;

  const loading = useSelector(store => store.loading);

  useEffect(()=>{
    getDID()

    getClusterList().then(rs => {
      const clusterRS = [];


      rs?.data?.forEach((item) => {
        const clusterId = '' + item.clusterId;
        if (clusterId.indexOf("0x") < 0) return;

        clusterRS.push({
          label: JSON.parse(item.metaData)['name'],
          key: item.clusterId,
        })
      })
      // setClusterList(clusterRS);
      store.dispatch(saveClusterList(clusterRS))
    });
  },[])

  const getDID = async() =>{
    const net = import.meta.env.VITE_CHAIN;
    const rpcUrl = CHAIN_LIST[net].CKB_NODE_RPC_URL
    const indexUrl = CHAIN_LIST[net].CKB_INDEXER_URL
    const indexer = new Indexer(indexUrl, rpcUrl);

    const  args =net==="testnet"?"0x80f0d4bf6b3951911aa6b98cc609d477a8a10b903b35cfd528d098e95c36f680":"0x62312cd846659e188b05da11dc3f080b083c27371ea701d6026e11e713e0e3de"
    const didRT = indexer.collector({
      type: {
        script: {
          codeHash:
              "0x00000000000000000000000000000000000000000000000000545950455f4944",
          hashType: "type",
          args,

        },
        searchMode: "exact",
      }
    });
    let rt = await didRT.collect();
    let didRTJSON = await rt.next()
    const {outPoint:{txHash}} = didRTJSON.value
    store.dispatch(saveHash(txHash))
  }


  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }


  const toggleSidebar = () => {
    setIsColleapse(!isColleapse);
  };

  const NaviTo = (endpoint) => {
    if(endpoint.indexOf("transfer") > -1){
      navigate(`${endpoint}/spore`)
    }else{
      navigate(`${endpoint}/dobby`)
    }

  }

  return <div className='h-full shrink-0'>
    {
        loading &&      <Loading />
    }

    {!isMobile ? (
        <div
            className={`pt-8 ${isColleapse ? 'left-0 w-47' : 'w-14'}  h-full bg-white text-black transition-width duration-300`}>
          <div className='h-12 relative flex items-center justify-center group'>
            <div className=' font-Montserrat text-hd2mb capitalize logo flex gap-4 pl-2 pb-5 items-center justify-center'>
              <img src={LogoImg} alt=""/>
            </div>
            <div
                className='cursor-pointer absolute right-[-10px] w-6 h-6 flex justify-center items-center rounded-full border-2 border-white001 opacity-50 group-hover:opacity-100 transition-opacity duration-200 text-black bg-white'
                onClick={toggleSidebar}
            >
              {
                isColleapse ? <ChevronsLeft/> : <ChevronsRight/>
              }
            </div>
          </div>

          {
            items.map((item, index) => (<div key={index}
                className={activeTab.indexOf(`/${item.key}`) > -1? 'flex flex-col mt-8 border-l-primary011 border-l-4 rounded-l' : 'flex flex-col mt-8 border-l-white border-l-4'}
                onClick={(e) => {
                  e.preventDefault()
                  NaviTo(item.key)
                }}
            >
              <div className='flex items-center px-4 gap-2 py-1 cursor-pointer'>
                <Icon iconNode={item.icon} className={activeTab.indexOf(`/${item.key}`) > -1 ? 'text-primary011' : ''} />
                {
                    isColleapse &&
                    <p className={`font-SourceSanPro text-body1mb ${activeTab.indexOf(`/${item.key}`) > -1? 'font-semibold text-primary011' : ''}`}>{item.label}</p>
                }
              </div>
            </div>))
          }




          <LinkBox isopen={isColleapse.toString()}>
            <a href="https://t.me/hastepro" target="_blank" rel="noreferrer"> <img src={TgLogo} alt=""/>
              <span>Telegram</span></a>

          </LinkBox>

        </div>
    ) : (
        // 移动视图
        <>
          <div
              className="fixed top-0 px-4 left-0 w-full h-16 bg-gray-100 text-black flex justify-between items-center z-50">
            {
              isOpen ? <AlignJustify className='rotate-90' onClick={toggleMobile}/> :
                  <AlignJustify onClick={toggleMobile}/>
            }
            {/*<DropdownSelect/>*/}
          </div>
          <div
              className={`fixed top-0 left-0 bg-white w-full text-black h-full z-40 transform ${isOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out`}>
            <button onClick={toggleMobile} className="text-black p-4">Close</button>
            <div>
              <div className='flex flex-col'
                   onClick={(e) => {
                     e.preventDefault()
                     setIsOpen(false)
                     NaviTo("/dashboard")
                   }}
              >
                <div className='flex items-center px-4 gap-4 py-4 cursor-pointer'>
                  <LayoutDashboard className={` ${activeTab === '/' ? ' text-primary011' : ''}`}/>
                  {
                      isColleapse &&
                      <p className={` text-body1mb ${activeTab === '/' ? 'font-Montserrat text-primary011' : ''}`}>Dashboard</p>
                  }
                </div>
              </div>
              <div className='flex flex-col' onClick={(e) => {
                e.preventDefault()
                setIsOpen(false)
                NaviTo("/transaction")
              }}>
                <div className='flex items-center px-4 gap-4 py-4 cursor-pointer'>
                  <NotebookText className={` ${activeTab === '/transaction/' ? ' text-primary011' : ''}`}/>
                  {
                      isColleapse &&
                      <p className={`text-body1mb ${activeTab === '/transaction/' ? 'font-Montserrat text-primary011' : ''}`}>Transaction</p>
                  }
                </div>
              </div>
              <div className='flex flex-col'
                   onClick={(e) => {
                     e.preventDefault()
                     setIsOpen(false)
                     NaviTo("/send")
                   }}
              >
                <div className='flex items-center px-4 gap-4 py-4 cursor-pointer'>
                  <SendToBack className={` ${activeTab === '/send/' ? ' text-primary011' : ''}`}/>
                  {
                      isColleapse &&
                      <p className={`text-body1mb ${activeTab === '/send/' ? 'font-Montserrat text-primary011' : ''}`}>Send
                        & Receive</p>
                  }
                </div>
              </div>
              <LinkBox isopen={isColleapse.toString()}>
                <a href="https://t.me/hastepro" target="_blank" rel="noreferrer"> <img src={TgLogo} alt=""/>
                  <span>Telegram</span></a>

              </LinkBox>
            </div>
          </div>
        </>
    )}
  </div>
}
