
import React, { useEffect, useState } from 'react';
import { notification, Table} from 'antd';
// import BuyModal from "../components/Buy.jsx";
import styled from "styled-components";
import {shortAddress, statusTag} from "../../utils/global.js";
import { formatUnit } from "@ckb-lumos/bi";
import {getMyOrders, sendTx} from "../../api/dexRpc.js";
import { useSelector } from "react-redux";
import store from "../../store/index.js";
import { saveLoading } from "../../store/reducer.js";
import { cancel } from "../../utils/txHelper.js";
import ImageComponent from "../image.jsx";
import CkbImg from "../../assets/ckb.png";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PAGE_SIZE} from "../../utils/const.js";

const Box = styled.div`
    padding: 30px;
.nft{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
`

const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img{
        width: 24px;
        height: 24px;
    }
`


// eslint-disable-next-line react/prop-types
export default function MyOrder() {
    const account = useSelector((store) => store.account);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();
    // const [showBuy, setShowBuy] = useState(false);
    const [list, setList] = useState([])
    const [selectItem, setSelectItem] = useState([]);
    const [more, setMore] = useState(false)
    const [last,setLast] = useState('');
    const [search] = useSearchParams();
    const page = search.get("page") ?? 1;

    const clusterList = useSelector(store => store.clusterList);

    const findClusterName = (clusterId) => {
        const cluster = clusterList.find(item => item.key == clusterId);
        return cluster?.label;
    }

    const columns = [
        {
            title: 'DOB',
            dataIndex: 'nft',
            render: (_, record) => <ImageComponent tokenKey={record?.dobsId} size="50px" />
        },
        {
            title: 'DOB ID',
            dataIndex: 'DobId',
            render: (_, record) => <span>{shortAddress(record?.dobsId)}</span>
        },
        {
            title: 'Tx',
            dataIndex: 'tx',
            render: (_, record) => <span>{shortAddress(record?.txHash)}</span>
        },
        {
            title: 'ClusterId',
            dataIndex: 'clusterId',
            render: (_, record) => <span>{shortAddress(record?.clusterId)}</span>
        },
        {
            title: 'Cluster Name',
            render: (_, record) => <span>{findClusterName(record?.clusterId)}</span>
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            render: (_, record) => <span className={`status_${record?.status}`}>{statusTag(record?.status)}</span>
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (_, record) => <PriceBox> <img src={CkbImg} alt="" />{formatUnit(record?.price, 'ckb')} <span>CKB</span></PriceBox>
        },
    ];

    useEffect(() => {
        if (!account) return
        getList()
    }, [account]);

    const getList = async () => {


        const result = await getMyOrders(account, page, PAGE_SIZE, ``)


        let newList = []
        newList = [...result.data];

        setList(newList);
        setLast(Number(page)+1)


        setMore(result.total > (Number(page)) * PAGE_SIZE);
    }



    const getMore = () => {
        navigate(`?page=${last}`)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        let newSeletItem = []
        list.map((item) => {
            newSelectedRowKeys.map((sl) => {
                if (item.id === sl) {
                    newSeletItem.push(item)
                }
            })
        })

        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const isListed = (record) =>{
        if(record.status !== 1) {
            return true;
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps:(record) => ({
            disabled: isListed(record)
        })
    };
    const hasSelected = selectedRowKeys.length > 0;

    // const handleClose = () => {
    //     setShowBuy(false);
    // }
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const handleResult = (type, tip, desc) => {
        openNotificationWithIcon(type, tip, desc)
        getList()
    }

    const handleCancel = async () => {
        if (!selectItem?.length) {
            return
        }

        console.log(selectItem);


        let assetList = [];
        for (let i = 0; i < selectItem.length; i++) {
            const item = selectItem[i];
            if (item.status != 1) {
                // alert('Status is not UnList')

                openNotificationWithIcon("error", 'Status is not UnList')
                return;
            }

            assetList.push({
                args: item.dobsId,
                assetType: item.assetType
            })
        }

        store.dispatch(saveLoading(true));
        //
        try {
            let txSkeletonObj = await cancel(assetList, account);

            let rawTx = await window.ckb.request({
                method: "ckb_signRawTransaction",
                data: {
                    txSkeleton: txSkeletonObj
                }
            });

            // console.log('------ buy', JSON.stringify(rawTx, null, 2));
            let sendRs = await sendTx(rawTx);
            let txHash = sendRs.txHash;

            handleResult('success', "Success", txHash);
        } catch (e) {
            console.error("submitBuy", e)
            // handleResult('error', "Failed", e.message)
        } finally {
            store.dispatch(saveLoading(false));
        }
    }

    return <div className="w-full h-full overflow-auto">

        {/* {
            showBuy && <BuyModal handleClose={handleClose} show={showBuy} selectItem={selectItem} handleResult={handleResult} />
        } */}
        {contextHolder}
        <Box>
            <div className="flex justify-between items-center mb-2"
            >
                <button className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed" type="primary" disabled={!selectItem?.length} onClick={() => handleCancel()}>
                    Cancel
                </button>

                <span
                    className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}
                </span>
            </div>
            <Table rowSelection={rowSelection} rowKey="id" columns={columns} dataSource={list} pagination={false}/>
            {/*{*/}
            {/*more && <PageBox onClick={() => getMore()}>Load more</PageBox>*/}
            {/*}*/}

            <PageBox>
                {
                    page!=1 && <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    more && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>

        </Box>
    </div>
}
