import {Route,Routes,Navigate} from "react-router-dom";
import Market from '../pages/market.jsx';
import MyNFT from "../pages/dobs.jsx";
import Orders from "../pages/orders.jsx";
import TransferOuter from "../pages/TransferOuter.jsx";

function RouterLink() {
    return (

        <Routes>
            <Route path="/" element={<Navigate to="/marketplace/dobby" />}/>
            <Route path="/marketplace/:platform" element={<Market />}/>
            {/* <Route path="/test" element={<Test />}/> */}
            <Route path="/myDOBs/:platform" element={<MyNFT />}/>
            <Route path="/myOrders/:platform" element={<Orders />}/>
            <Route path="/transfer/:type" element={<TransferOuter />}/>

        </Routes>

   );
}

export default RouterLink;
