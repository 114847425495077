import {useState} from "react";
import { ChevronsLeft, ChevronsRight} from 'lucide-react';

import {Spore} from "../../utils/const.js";
import {useNavigate, useParams} from "react-router-dom";


export default function SporeType() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [isColleapse, setIsColleapse] = useState(true);
    const {type} = useParams();

    const navigate = useNavigate();


    const handleTo = (str) =>{
        navigate(`/transfer/${str}`)
    }


    const toggleSidebar = () => {
        setIsColleapse(!isColleapse);
    };

    return <div className='h-full'>
        {!isMobile ? (
            <div className={`pt-3
            ${isColleapse ? 'left-0 w-48' : 'w-16'} 
            h-full bg-gray-100 transition-width duration-300 border-r border-gray-300`}>
                <div className='h-12 relative flex items-center justify-center group'>
                    <div className={`w-full font-Montserrat text-hd2mb ${isColleapse ? '' : 'flex justify-center'}`}>
                        {isColleapse && <>
                            <div className='w-full flex justify-between px-8'>
                                <p>Type</p>

                            </div>
                        </>}

                    </div>
                    <div
                        className='cursor-pointer absolute right-[-10px] w-6 h-6 flex justify-center items-center rounded-full border-2 border-white001  bg-white transition-opacity duration-200'
                        onClick={toggleSidebar}
                    >
                        {
                            isColleapse ? <ChevronsLeft className="opacity-10 group-hover:opacity-100 "/> :
                                <ChevronsRight className="opacity-10 group-hover:opacity-100 "/>
                        }
                    </div>
                </div>
                <div className='flex flex-col mt-3 border-t border-gray-200'>
                    {Spore.map((item,index) => (
                        <div key={index} className={` flex items-center py-2 border-b border-gray-200 ba cursor-pointer firstLi ${item.path.indexOf(type) > -1 ? 'bg-white' : 'bg-gray-100'}
              `} onClick={()=>handleTo(item.path)}>
                            <div className='py-2 px-4 pr-1 shrink-0'>
                                <img className='border rounded-full overflow-hidden '
                                     src={item.icon} width={30} height={30}
                                     />
                            </div>
                            {
                                isColleapse &&
                                <>
                                    <div className='w-full px-2 '>
                                        <div className='w-full flex justify-between'>
                                            <div className=' font-SourceSanPro '>{item.name}</div>
                                            {/* <div className=' font-SourceSanPro font-semibold text-primary003'> ${wallet}</div> */}
                                        </div>
                                        <div className='flex'>
                                            {/*<div*/}
                                            {/*    className='font-SourceSanPro text-black opacity-30 uppercase'>{wallet.chain} {wallet.balance}</div>*/}
                                        </div>
                                    </div>

                                </>
                            }
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            // Mobile View
            <div className="fixed top-0 left-0 w-full h-12 bg-gray-800 flex items-center">
                <button onClick={toggleSidebar} className="m-2 p-2 bg-gray-700 rounded">
                    Menu
                </button>
            </div>
        )}
    </div>
}
