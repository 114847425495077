
import { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import BuyModal from "./modal/Buy.jsx";
import styled from "styled-components";
import {formatPrice, shortAddress} from "../../utils/global.js";
import { formatUnit } from "@ckb-lumos/bi";
import CkbImg from "../../assets/ckb.png";
import store from "../../store/index.js";
import { saveLoading } from "../../store/reducer.js";
import {getJoyidCategeoryList, getMarketList} from "../../api/joyid.api.js";
import { Select } from 'antd';
import ImageComponent from "../image.jsx";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PAGE_SIZE} from "../../utils/const.js";
import useIDB from "../../useHook/useIndexDB.jsx";


const Box = styled.div`
    padding: 30px;
.nft{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
    .usdt{
        margin-left: 34px;
        color: #ff9900;
    }
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img{
        width: 24px;
        height: 24px;
    }
`

const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span{
        padding: 20px;
        cursor: pointer;
        text-decoration: underline;
    }

`

const FlexLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`


export default function Home() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [showBuy, setShowBuy] = useState(false);
    const [list, setList] = useState([])
    // const [page, setPage] = useState(0)
    const [selectItem, setSelectItem] = useState([]);
    const [more, setMore] = useState(false)
    const [selectList, setSelectList] = useState([]);
    // const [currentCat, setCurrentCat] = useState("");
    // const [currentClusterName, setCurrentClusterName] = useState("");
    const [priceUsdt, setPriceUsdt] = useState("");
    const [last,setLast] = useState('');
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const page = search.get("page") ?? "";
    const category = search.get("category") ?? "";

    const {isExist} = useIDB();


    // <img className="nft" src="https://arseed.web3infra.dev/0kNCtP7aiArSYolnBOedfpUEI9HUKrs21BD7rIRGsVw"/>
    const columns = [
        {
            title: 'DOB',
            dataIndex: 'nft',
            render: (_, record) => <ImageComponent size="50px" tokenKey={`0x${record?.token_key}`} />
        },
        {
            title: 'DOB ID',
            dataIndex: 'token_key',
            render: (_, record) => <span>{shortAddress(record.token_key,8)}</span>
        },
        {
            title: 'Cluster Name',
            dataIndex: 'name',
            render: (_, record) => <span>{record.name}</span>
        },
        {
            title: 'Owner',
            render: (_, record) => <span>{shortAddress(record.ckb_address,8)}</span>
        },
        // {
        //     title: 'Cluster Name',
        //     render: () => <span>{currentClusterName}</span>
        // },

        // {
        //     title: 'Tx',
        //     dataIndex: 'tx',
        //     render: (_, record) => <span>{shortAddress(record?.out_point?.tx_hash)}</span>
        // },
        // {
        //     title: 'Occupied',
        //     dataIndex: 'occupied',
        //     render: (_, record) => <Tag>&lt;{formatUnit(record.Occupied,'ckb') } CKBytes&gt;</Tag>
        // },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (_, record) => <div>
                <div><PriceBox><img src={CkbImg} alt=""/>{formatUnit(record.price, "ckb")} <span>CKB</span></PriceBox>
                </div>
                <div className="usdt">$ {formatPrice(record,priceUsdt)}</div>
            </div>
        },
    ];

    useEffect(() => {
        getCatList()
        // getList(1)
    }, []);

    useEffect(() => {
        getList()
    }, [page,category]);

    // useEffect(() => {
    //     if (!currentCat) return;
    //     getList(1)
    //
    // }, [currentCat]);

    const getList = async () => {
        store.dispatch(saveLoading(true));
        try {
            setSelectItem([]);
            setSelectedRowKeys([]);
            const rt = await getMarketList(page, category);
            const {next_cursor, orders, ckb_usd} = rt;
            setPriceUsdt(ckb_usd)
            setMore(!!next_cursor)
            let newList = []
            // if (pg === 1) {
            //     newList = [...orders];
            // } else {
            //     newList = [...list, ...orders]
            // }



            newList = [...orders];

            setList(newList);
            setLast(next_cursor)
        } catch (e) {
            console.error("==getCatList=", e)

        } finally {
            store.dispatch(saveLoading(false));
        }
    }

    const getCatList = async () => {
        store.dispatch(saveLoading(true));
        try {
            const rt = await getJoyidCategeoryList();
            const item = rt.nfts;
            setSelectList(item)
            // setCurrentCat(item[0].cluster_id)
            // setCurrentClusterName(item[0].name)
        } catch (e) {
            console.error("==getCatList=", e)

        } finally {
            store.dispatch(saveLoading(false));
        }

    }



    const getMore = () => {
        // getList(page + 1)
        navigate(`?page=${last}&category=${category??""}`)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        let newSeletItem = []
        list.map((item) => {
            newSelectedRowKeys.map((sl) => {
                if (item.token_key === sl) {
                    newSeletItem.push(item)
                }
            })
        })
        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps:(record) => ({
            disabled: isExist(record.out_point)
        })
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleClose = () => {
        setShowBuy(false);
    }
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const handleResult = (type, tip, desc) => {
        openNotificationWithIcon(type, tip, desc)
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }

    // useEffect(() => {
    //     if(!category)return;
    //     const item = selectList.find(inner => inner.cluster_id === category);
    //     setCurrentClusterName(item.name)
    // }, [category]);


    const handleChange = (value) => {
        // setCurrentCat(value)
        // const item = selectList.find(inner => inner.cluster_id === value);
        // setCurrentClusterName(item.name)
        navigate(`?page=1&category=${value??""}`);
    };

    return <div className="w-full h-full overflow-auto">

        {
            showBuy &&
            <BuyModal handleClose={handleClose} show={showBuy} selectItem={selectItem} handleResult={handleResult} priceUsdt={priceUsdt} />
        }
        {contextHolder}
        <Box>
            <FlexLine>
                <div>
                    <Select
                        value={category}
                        className="w-[420px] mr-4"
                        size="large"
                        allowClear={true}
                        placeholder="Select Cluster"
                        onChange={handleChange}
                        options={selectList}
                        fieldNames={{
                            label: "name",
                            value: "cluster_id"
                        }}
                    />
                    <button className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed"  disabled={!selectItem?.length} onClick={() => setShowBuy(true)}>
                        Buy
                    </button>
                </div>

                <span className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}
                </span>
            </FlexLine>
            <Table rowSelection={rowSelection} rowKey="token_key" columns={columns} dataSource={list}
                   pagination={false}/>
            {/*{*/}
            {/*    more && <PageBox onClick={() => getMore()}>Load more</PageBox>*/}
            {/*}*/}

            <PageBox>
                {
                    page!=1 &&  <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    more && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>

        </Box>
    </div>
}
