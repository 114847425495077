export default {
  account: null,
  connectData:null,
  loading: null,
  signature:null,
  showSign:null,
  joyidInfo:null,
  utxo:[],
  didHash:null,
  clusterList:[]
};
