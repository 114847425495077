import {useEffect, useState} from "react";
import store from "../store/index.js";
import {saveLoading} from "../store/reducer.js";
import {predefined} from "@ckb-lumos/config-manager";
import {getSporeScript, predefinedSporeConfigs, unpackToRawClusterData, unpackToRawSporeData} from "@spore-sdk/core";
import {config, helpers, Indexer} from "@ckb-lumos/lumos";
import {CHAIN_LIST} from "../utils/const.js";
import {useSelector} from "react-redux";
const { CKB_NODE_RPC_URL, CKB_INDEXER_URL, DOB_AGGREGATOR_URL, JOYID_APP_URL, CONFIG} = CHAIN_LIST[import.meta.env.VITE_CHAIN];

export default function ClusterName({clusterId}){
    const [loading,setLoading] = useState(true);
    const account = useSelector(store => store.account);
    const [clusterName, setClusterName] = useState('');

    useEffect(() => {
        getName()
    }, [clusterId]);

    const getName = async() =>{
        setLoading(true)
        if(!clusterId){
            setClusterName("...")
            return;
        }
        try{

            const net = import.meta.env.VITE_CHAIN;
            const lumosConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;;
            const clusterConfig = net==="testnet"?  predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;
            const version = net === 'testnet'?"preview":"latest";

            const sporeTypeScript = getSporeScript(clusterConfig,"Cluster",["v2",version]);

            config.initializeConfig(lumosConfig);

            const indexer = new Indexer(CKB_INDEXER_URL, CKB_NODE_RPC_URL);
            const collector = indexer.collector({
                type: {
                    codeHash: sporeTypeScript?.script.codeHash,
                    hashType: sporeTypeScript?.script.hashType,
                    args: clusterId,
                },
            });


            let rt = await collector.collect();
            let rtJSON = await rt.next()

            const nameCl = unpackToRawClusterData(rtJSON.value.data)

            setClusterName(nameCl?.name)


        }catch(e){
            console.error(e);
        }finally {
            setLoading(false)
        }
    }

    return <div>
        {
            loading && <div>...</div>
        }
        {
            !loading && <div>{clusterName}</div>
        }

    </div>
}
