import {Omiga_URL} from "../utils/const.js";



export const getList_Omiga = async (type,page,pageSize,hash) => {


    const res = await fetch(Omiga_URL[import.meta.env.VITE_CHAIN] + `api/v1/nfts/nft_list?type_hash=${hash}&page_index=${page}&limit=${pageSize}&sort=volume_24h&udt_type=${type}`);
    let rt = await res.json();
    return rt.data;
};

export const getList_Omiga_xudt = async (page,pageSize) => {
    const res = await fetch(Omiga_URL[import.meta.env.VITE_CHAIN] + `api/v1/markets/token_list?page_index=${page}&limit=${pageSize}&sort=volume_24h`);
    let rt = await res.json();
    return rt.data;
};


export const getList_Omiga_doblist = async (type_hash,page,pageSize) => {
    const res = await fetch(Omiga_URL[import.meta.env.VITE_CHAIN] + `api/v1/nfts/sell_orders?type_hash=${type_hash}&page_index=${page}&limit=${pageSize}`);
    let rt = await res.json();
    return rt.data;
};
export const getList_Omiga_udtlist = async (type_hash,page,pageSize) => {
    const res = await fetch(Omiga_URL[import.meta.env.VITE_CHAIN] + `api/v1/markets/sell_orders?type_hash=${type_hash}&page_index=${page}&limit=${pageSize}`);
    let rt = await res.json();
    return rt.data;
};
