
import Routerlink from './router/router';
import {BrowserRouter as Router} from "react-router-dom";
import GlobalStyle from "./utils/GlobalStyle";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

import Menu from "./components/layout/menu.jsx";

import { DBConfig } from "./utils/DBconfig";
import { initDB } from "react-indexed-db-hook";
import styled from "styled-components";
import { ConfigProvider } from 'antd';
import * as Sentry from "@sentry/react";

initDB(DBConfig);

if(import.meta.env.VITE_CHAIN === "mainnet"){

    console.log("Sentry init")
    Sentry.init({
        dsn: "https://05d492898f57f0f544bb989017fbc795@o4507175892353024.ingest.us.sentry.io/4507972886069248",
        integrations: [
            Sentry.replayIntegration(),
        ],

        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}



const BottomBox = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: #fff;
    font-size: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(17, 24, 39,0.5);
    border-top: 1px solid #f2f2f2;
    gap: 10px;
`

function App() {


  return (
      <Provider store={store}>
          <ConfigProvider
              theme={{
                  token: {
                      colorPrimary: '#ff9500',
                      fontFamily: "Roboto-Regular",
                  },
                  components: {
                      // Tabs: {
                      //     colorText: "#fff",
                      //     fontSize: "16px"
                      // },
                      // Button: {
                      //     colorPrimary: '#141414',
                      //     colorPrimaryHover: "#141414",
                      //     colorPrimaryActive: "#141414",
                      //     defaultBg: "#141414",
                      //     defaultBorderColor: "#424242",
                      // },

                      Input: {
                          colorText: '#000',
                          colorTextPlaceholder: 'rgba(0, 0, 0, 0.6)',
                          colorBgContainer: "#fff",
                          colorBorder: "rgba(0, 0, 0, 0.1)"
                      },
                      Table: {
                          headerBg: "rgba(0,0,0,0.03)",
                          headerColor: "#000",
                          colorText: '#000',
                          colorBgContainer: "#fff",
                          borderColor: "#eee",
                          headerSplitColor: "#eee",
                          rowHoverBg:"#ff950010",
                          rowSelectedBg: "#ff950010",
                          rowSelectedHoverBg: "#ff950010",
                          cellPaddingBlock:"10px"
                      },
                      // Progress: {
                      //     remainingColor: "rgba(255, 255, 255, 0.12)",
                      //     circleTextColor: "#c8c8c8",
                      //     colorText: "rgba(255, 255, 255, 0.85)",
                      // },
                      Pagination: {
                          itemActiveBg: "#141414",
                          colorPrimary: "#ff9500",
                          colorText: 'rgba(255, 255, 255, 0.85)',
                      },
                      // Modal: {
                      //     contentBg: "#141414",
                      //     headerBg: "#141414",
                      //     colorText: 'rgba(255, 255, 255, 0.85)',
                      //     titleColor: '#fff',
                      //     colorIcon: 'rgba(255, 255, 255, 0.45)',
                      //     colorIconHover: 'rgba(255, 255, 255, 0.85)',
                      // },
                      // Tag: {
                      //     defaultBg: "#141414",
                      //     colorText: "rgba(255, 255, 255, 0.85)",
                      //     colorBorder: "#424242"
                      // },
                      // Radio: {
                      //     buttonBg: "#141414",
                      //     colorBorder: "#303030",
                      //     buttonColor: 'rgba(255, 255, 255, 0.85)',
                      //     buttonCheckedBg: "#141414",
                      //     buttonSolidCheckedBg: "#Fff"
                      //
                      // },
                      // Slider: {
                      //     railBg: "rgba(255, 255, 255, 0.2)",
                      //     railHoverBg: "rgba(255, 255, 255, 0.2)",
                      //     trackBg: "#ff9500",
                      //     handleColor: "#ff9500",
                      //     dotSize: 6,
                      //     trackHoverBg: "#ff9500"
                      // },
                      // Popover: {
                      //     zIndexPopup: 99999,
                      //     sizePopupArrow: 0,
                      //     colorBgElevated: "#191A1E",
                      //     colorText: "rgba(255, 255, 255, 0.85)",
                      // },
                      Select: {
                          colorBgContainer: "#fff",
                          colorText: "#000",
                          optionActiveBg: "rgba(255, 255, 255, 0.1)",
                          optionSelectedBg: "rgba(255, 255, 255, 0.1)",
                          colorBorder: "rgba(0, 0, 0, 0.1)",
                          optionHeight:"40px"
                      },
                      Notification: {
                          colorPrimary: "#fff",
                          colorIcon: "#000",
                          colorWarning: "#fff",
                          colorTextDescription: "#fff",
                      },
                      App: {
                          colorText: "#fff",
                          fontSize: 14
                      }
                  },
              }}
          >
              <PersistGate loading={null} persistor={persistor}>
                  <Router>
                      <div className="flex h-full">
                          <Menu/>
                          <Routerlink/>
                          <GlobalStyle/>
                      </div>
                      <BottomBox
                          style={{
                              textAlign: 'center',
                          }}
                      >
                          © Taoist Labs {new Date().getFullYear()}
                      </BottomBox>
                  </Router>
              </PersistGate>
          </ConfigProvider>
</Provider>
)
}

export default App
