import { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import styled from "styled-components";
import {shortAddress, statusTag} from "../../utils/global.js";
import { formatUnit } from "@ckb-lumos/bi";
import {getAllListed, getListed} from "../../api/dexRpc.js";
import ImageComponent from "../image.jsx";
import BuyModal from "./modal/Buy.jsx";
import ClusterSelect from "./cluster.jsx";
import CkbImg from "../../assets/ckb.png";
import {PAGE_SIZE} from "../../utils/const.js";
import {useNavigate, useSearchParams} from "react-router-dom";

const Box = styled.div`
    padding: 30px;
.nft{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}


`

const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img{
        width: 24px;
        height: 24px;
    }
`


// eslint-disable-next-line react/prop-types
export default function ReiHome() {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectCluster,setSelectCluster] = useState(null);
    const [selectClusterName,setSelectClusterName] = useState(null);
    const [showBuy, setShowBuy] = useState(false);
    const [list, setList] = useState([])
    const [selectItem, setSelectItem] = useState([]);
    const [more, setMore] = useState(false)

    const [last,setLast] = useState('');

    const navigate = useNavigate();

    // let curPage = 1;
    // let totalPage = 99;
    // const pageSize = 10;


    const [search] = useSearchParams();
    const category = search.get("category");
    const page = search.get("page") ?? 1;

    const formatCluster = (data) =>{
        if(data && typeof data === "object"){
            let jsonData = JSON.parse(data);
            return jsonData.name
        }
        return ""

    }


    const columns = [
        {
            title: 'DOB',
            dataIndex: 'nft',
            render: (_, record) => <ImageComponent tokenKey={record?.dobsId} size="50px"  isDid={record.assetType === 1} />
        },
        {
            title: 'DOB ID',
            dataIndex: 'DobId',
            render: (_, record) => <span>{shortAddress(record?.dobsId)}</span>
        },
        {
            title: 'Cluster Name',
            dataIndex: 'name',
            render: (_, record) => <span>{selectClusterName || formatCluster(record?.clusterMetaData)}</span>
        },
        // {
        //     title: 'Cluster Name',
        //     // dataIndex: 'clusterId',
        //     // render: (_, record) => <span>{shortAddress(record?.clusterId)}</span>
        // },
        {
            title: 'Owner',
            render: (_, record) => <span>{shortAddress(record.ownerAddr,8)}</span>
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'Status',
        //     render: (_, record) => <span className={`status_${record?.status}`}>{statusTag(record?.status)}</span>
        // },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (_, record) => <PriceBox><img src={CkbImg} alt=""/>{formatUnit(record.price, "ckb")} <span>CKB</span></PriceBox>
        },
    ];


    useEffect(() => {
        if(!category){
            getAllList()
        }else{
            getList()
        }

    }, [page,category]);

    // useEffect(()=>{
    //     navigate(`?page=1&category=${selectCluster??""}`);
    // },[selectCluster])

    const getList = async () => {
        if (selectCluster === "") return

        const result = await getListed(category, page, PAGE_SIZE)

        let newList = []
        newList = [...result.data];

        setList(newList);
        setLast(Number(page)+1)
        setMore(result.total > (Number(page)) * PAGE_SIZE);

    }


    const getAllList = async () => {
        try{
            const result = await getAllListed(page, PAGE_SIZE)

            let newList = []
            newList = [...result.data];

            setList(newList);
            setLast(Number(page)+1)
            setMore(result.total > (Number(page)) * PAGE_SIZE);
        }catch(error){
            console.log(error);
        }


    }




    const getMore = () => {
        // getList(page + 1)
        navigate(`?page=${last}&category=${category??""}`)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        let newSeletItem = []
        list.map((item) => {
            newSelectedRowKeys.map((sl) => {
                if (item.id === sl) {
                    newSeletItem.push(item)
                }
            })
        })

        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };



    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleClose = () => {
        setShowBuy(false);
    }
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, tips, desc) => {
        api[type]({
            message: tips,
            description: desc,
            duration: 2000,
        });
    };

    const handleResult = (type, tip, desc) => {
        openNotificationWithIcon(type, tip, desc)
        getList()
    }

    return <div className="w-full h-full overflow-auto">

        {
            showBuy && <BuyModal handleClose={handleClose} show={showBuy} selectItem={selectItem} handleResult={handleResult} />
        }
        {contextHolder}
        <Box>
            <div className="flex justify-between items-center mb-2">
                <div className="flex">
                    <ClusterSelect setSelectCluster={setSelectCluster} selectCluster={selectCluster} setSelectClusterName={setSelectClusterName} />
                    <button className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed"  disabled={!selectItem?.length} onClick={() => setShowBuy(true)}>
                        Buy
                    </button>
                </div>
                <span className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}

                </span>
            </div>
            <Table rowSelection={rowSelection} rowKey="id" columns={columns} dataSource={list} pagination={false}/>
            {/*{*/}
            {/*    more && <PageBox onClick={() => getMore()}>Load more</PageBox>*/}
            {/*}*/}

            <PageBox>
                {
                    page!=1 && <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    more && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>


        </Box>
    </div>
}
