import {useEffect, useState} from 'react';
import { notification, Table, InputNumber, Switch } from 'antd';
import ListModal from "./modal/list.jsx";
import styled from "styled-components";
import {shortAddress} from "../../utils/global.js";
import {useSelector} from "react-redux";
import {getSporesByRPC} from "../../api/index.js";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {PAGE_SIZE} from "../../utils/const.js";
import ImageComponent from "../image.jsx";
import {unpackToRawSporeData} from "@spore-sdk/core";
import ClusterName from "../clusterName.jsx";
import {useNavigate, useSearchParams} from "react-router-dom";
import useIDB from "../../useHook/useIndexDB.jsx";
import { parseUnit} from "@ckb-lumos/bi";

const Box = styled.div`
    padding: 30px;
.nft{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
    
`
const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img{
        width: 24px;
        height: 24px;
    }
`

const PageBox = styled.div`
    background: #f5f5f5;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span{
        padding: 20px;
        cursor: pointer;
        text-decoration: underline;
    }
 
`


const FlexLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .lftTop{
        display: flex;
        align-items: center;
        gap: 20px;
    }
`


export default function MyNFT(){
    const account = useSelector(store => store.account);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showList, setShowList] = useState(false);
    const [list,setList] = useState([])
    const [last,setLast] = useState('');
    const [selectItem,setSelectItem]  = useState([]);
    const [more,setMore] = useState(false)
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const isSpore = search.get("isSpore") ?? "true";

    const {isExist} = useIDB();

    const page = search.get("page");

    const columns = [
        {
            title: 'DOB',
            render: (_, record) => <ImageComponent size="50px" tokenKey={record.output?.type?.args} data={record.output_data} isDid={isSpore === "false"} />

        },
        {
            title: 'DOB ID',
            render: (_, record) => <span>{shortAddress(record.output?.type?.args)}</span>
        },
        {
            title: 'Cluster ID',
            render: (_, record) => <span>{shortAddress(record.asset?.clusterId)}</span>
        },
        {
            title: 'Cluster Name',
            render: (_, record) => <ClusterName clusterId={record.asset?.clusterId} />
        },
        {
            title: 'Price (CKB)',
            render: (_, record) => <InputNumber size="large" className="w-[200px]" onChange={(e) => handlePrice(e,record)}/>
        }
    ];

    useEffect(() => {
        if(!account)return;
        getList()

    }, [account,page,isSpore]);



    const getList = async () =>{
        store.dispatch(saveLoading(true));
        try{
            setSelectItem([])
            setSelectedRowKeys([])
            let rt = await getSporesByRPC(account,PAGE_SIZE,page,isSpore === "true");
            const {objects,last_cursor} = rt;

            let arr = []

            for(let i=0;i<objects.length;i++){
                let item = objects[i];
                // const itemDobId = item.cellOutput.type.args;
                try{
                    const asset = unpackToRawSporeData(item.output_data);
                    arr.push({...item,asset,price:0});
                }catch(e){
                    arr.push({...item,price:0,asset:{
                            contentType:"image",
                            data:""
                        }});
                    console.error("Get dob info failed",e)
                }
            }

            setMore(last_cursor !== "0x")
            setList([...arr])
            setLast(last_cursor)

        }catch (e) {
            console.error(e)
        }finally {
            store.dispatch(saveLoading(false));
        }

    }


    const onSelectChange = (newSelectedRowKeys) => {
        let newSeletItem = []
        list.map((item)=>{
            newSelectedRowKeys.map((sl) =>{
                if(item.output?.type?.args === sl){
                    newSeletItem.push(item)
                }
            })
        })

        setSelectItem(newSeletItem);
        setSelectedRowKeys(newSelectedRowKeys);
    };


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps:(record) => ({
            disabled: isExist(record.out_point)
        })
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleClose = () =>{
        setShowList(false);
    }

    const handlePrice =(e,item) =>{
        item.price = parseUnit(e?.toString(),"ckb")?.toString()
        setSelectItem([...selectItem])
    }

    const getMore = () =>{

        navigate(`?page=${last}`)
    }

    const [api,contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type,tips,desc) => {
        api[type]({
            message: tips,
            description:desc,
            duration: 2000,
        });
    };

    const handleResult = (type,tip,desc,isEnd) =>{
        openNotificationWithIcon(type,tip,desc)
        if(isEnd){
            setTimeout(()=>{
                window.location.reload()
            },2000)
        }

    }


    const returnPrice = () =>{
        let itemArr  = selectItem.find((item)=> Number(item.price) <=0)
        return !!itemArr
    }

    const onChange = (checked) => {
        navigate(`?isSpore=${checked}`)
    };


    return <div className="w-full h-full overflow-auto">

        {
            showList && <ListModal handleClose={handleClose} show={showList} selectItem={selectItem}  handleResult={handleResult} isDid={isSpore === "false"}/>
        }

        {contextHolder}
        <Box>
            <FlexLine>
                <div className="lftTop">
                    <Switch value={isSpore === "true"} onChange={onChange} checkedChildren="Spore" unCheckedChildren="Did" />
                    <button className="text-sm h-9 bg-primary011 text-white w-20 border-0 rounded disabled:opacity-30 disabled:cursor-not-allowed" disabled={!selectItem?.length || returnPrice()}  onClick={() => setShowList(true)}>
                        List
                    </button>
                </div>


        <span className="text-sm text-slate-400">{hasSelected ? `Selected ${selectedRowKeys.length} item(s)` : ''}

        </span>
            </FlexLine>
            <Table rowSelection={rowSelection} rowKey={(row) => {
                return row.output?.type?.args;
            }} columns={columns} dataSource={list} pagination={false} />

            <PageBox>
                {
                    !!page && <span onClick={() => navigate(-1)}>previous</span>
                }
                {
                    more && PAGE_SIZE === list.length && <span onClick={() => getMore()}>next</span>
                }

            </PageBox>

        </Box>
    </div>
}
