import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {predefined} from "@ckb-lumos/config-manager";
import {CHAIN_LIST} from "../utils/const.js";
import {helpers, Indexer} from "@ckb-lumos/lumos";
import {BI, formatUnit} from "@ckb-lumos/bi";
import styled from "styled-components";


const Box = styled.div`
    display: flex;
    gap: 10px;
    font-size: 14px!important;
    margin-top: 5px;
`

export default function Balance() {
    const account = useSelector(store => store.account);

    const [balance,setBalance] = useState({})

    useEffect(() => {
        if(!account ) return;
        getBl()
        let timer = setInterval(() => {
            getBl()
        },20 * 1000 )
        return () =>{
            clearInterval(timer)
        }

    }, [account]);


    const getBl = async() =>{

        const net = import.meta.env.VITE_CHAIN

        const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;



        const rpcUrl = CHAIN_LIST[net].CKB_NODE_RPC_URL
        const indexUrl = CHAIN_LIST[net].CKB_INDEXER_URL
        const indexer = new Indexer(indexUrl, rpcUrl);

        let totalCapacity = BI.from(0);
        let OcCapacity = BI.from(0);


        const addressScript = helpers.parseAddress(account,{config:newConfig});

        const collector = indexer.collector({ lock: addressScript,scriptSearchMode:"exact"});

        for await (const cell of collector.collect()) {
            totalCapacity = totalCapacity.add(cell.cellOutput.capacity);
            if(cell.data !== "0x"){
                OcCapacity = OcCapacity.add(cell.cellOutput.capacity)
            }
        }

        let  total = totalCapacity.toString()
        let occ = OcCapacity.toString()


        setBalance({capacity:formatUnit(total,"ckb"),OcCapacity:formatUnit(occ,"ckb")});

    }

    return <Box>
        <div className="flex gap-5">
            <span className="text-slate-500">Balance</span>
            <div className="text-slate-500">
                Total:<span className="text-primary011 ml-1">{balance?.capacity}</span> <span
                className="sym">CKB</span>
            </div>
            <div className="text-slate-500">Occupied:<span className="text-primary011 ml-1">{balance?.OcCapacity}</span> <span
                className="sym">CKB</span></div>
        </div>

    </Box>
}
