import {useEffect} from 'react';
import {Select} from 'antd';

import {useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";



// eslint-disable-next-line react/prop-types
const ClusterSelect = ({ selectCluster, setSelectCluster,setSelectClusterName }) => {
    const clusterList = useSelector(store => store.clusterList);
    const navigate = useNavigate();

    const [search] = useSearchParams();
    const category = search.get("category");

    useEffect(() => {
        if(!clusterList.length)return;
        findClusterName()
    }, [category,clusterList]);

    const findClusterName = () => {
        let cluster;
        if(category){
            cluster = clusterList.find(item => item.key === category);
        }
        else{
            cluster = ""
            setSelectCluster("")
        }
        setSelectClusterName(cluster?.label) ;
    }

    const handleSelect = (v) =>{
        if(v){
            setSelectCluster(v)
            navigate(`?page=1&category=${v}`);
        }else{
            setSelectCluster("")
            navigate(`?page=1&category=`);
        }

    }

    return ( <Select
            className="w-[420px] mr-4"
                    value={category}
                    size="large"
                    allowClear={true}
                    onChange={(v) => handleSelect(v)}
                    options={clusterList}
                    fieldNames={{
                        label: "label",
                        value: "key"
                    }}
                />

    );
};
export default ClusterSelect;
